import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Link, useLoadRoute, useMatch } from '@tanstack/react-location';
import { format } from 'date-fns';

import { useCustomerQuotations } from '../../hooks/customer';

const CustomerQuotations = () => {
  const {
    params: { customerId },
  } = useMatch();
  const loadRoute = useLoadRoute();

  const { data } = useCustomerQuotations(customerId);
  return (
    <div className="w-full overflow-x-auto rounded-lg border border-gray-200">
      <table className="table w-full shadow">
        <thead>
          <tr>
            <th>เลขที่</th>
            <th>ผู้เสนอราคา</th>
            <th>วันที่สร้าง</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((quotation) => (
              <tr key={quotation.id}>
                <td>{quotation.running_number}</td>
                <td>{quotation.staff.name}</td>
                <td>
                  <span className="badge w-[100px]">
                    {format(new Date(quotation.create_date), 'dd/MM/yyyy')}
                  </span>
                </td>
                <td className="text-right">
                  <Link
                    className="btn btn-square btn-xs"
                    to={`/quotations/${quotation.running_number}`}
                    onMouseEnter={() =>
                      loadRoute({
                        to: `/quotations/${quotation.running_number}`,
                      })
                    }
                  >
                    <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                ไม่พบข้อมูล
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerQuotations;

import { Link } from '@tanstack/react-location';

const Home = () => {
  return (
    <div className="rounded-box bg-base-100 p-8 shadow-lg">
      <div className="ml-auto flex flex-col items-center justify-center">
        <Link to="/invoices" preload={1000} className="btn">
          Invoices
        </Link>
        <div className="divider">OR</div>
        <Link to="/quotations" preload={1000} className="btn">
          Quotations
        </Link>
        <div className="divider">OR</div>
        <Link to="/customers" preload={1000} className="btn">
          Customers
        </Link>
      </div>
    </div>
  );
};

export default Home;

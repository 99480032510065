import { Link } from '@tanstack/react-location';

import { useQuotations } from '../../hooks/quotation';

const Quotations = () => {
  const { data } = useQuotations();
  return (
    <div>
      {data &&
        data.map((quotation) => (
          <li key={quotation.running_number}>
            <Link to={`/quotations/${quotation.running_number}`} preload={2000}>
              {quotation.name} ({quotation.total})
            </Link>
          </li>
        ))}
    </div>
  );
};

export default Quotations;

import { Outlet, useMatchRoute } from '@tanstack/react-location';
import { Helmet } from 'react-helmet-async';

interface ILayout {
  label?: string;
}

const Layout = (props: ILayout) => {
  const { label } = props;
  const matchRoute = useMatchRoute();
  const isQuotationPrint = matchRoute({ to: '/quotations/:quotationId/print' });
  const isMFOPrint = matchRoute({ to: '/quotations/mfo/:mfoId/print' });
  return (
    <>
      {isQuotationPrint || isMFOPrint ? (
        <main className="mx-auto px-2 py-4">
          <Outlet />
        </main>
      ) : (
        <>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Helmet>
          <div className="flex min-h-screen overflow-hidden bg-slate-100">
            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
              <main className="container mx-auto px-2 py-4">
                {label && <h1 className="mb-4 text-center text-2xl font-bold">{label}</h1>}
                <Outlet />
              </main>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Layout;

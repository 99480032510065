import { useReducer } from 'react';

import { IWeightedProductCalculateResponse, IWeightedProductConfig } from '../interfaces/product';

export enum ActionType {
  INPUT_CHANGE = 'input_change',
  INPUT_TOGGLE = 'input_toggle',
  SET_PRODUCT_TYPE = 'set_product_type',
  SET_SPEC = 'set_spec',
  CALCULATE = 'calculate',
}

type Action =
  | {
      type: ActionType.INPUT_CHANGE;
      payload: {
        name: string;
        value: number | string;
      };
    }
  | {
      type: ActionType.INPUT_TOGGLE;
    }
  | {
      type: ActionType.SET_PRODUCT_TYPE;
      payload: {
        value: string;
        index: number;
      };
    }
  | {
      type: ActionType.SET_SPEC;
      payload: {
        value: string;
        index: number;
        dimensions: number;
      };
    }
  | {
      type: ActionType.CALCULATE;
      payload: IWeightedProductCalculateResponse;
    };

function reducer(state: IWeightedProductConfig, action: Action): IWeightedProductConfig {
  switch (action.type) {
    case ActionType.INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case ActionType.INPUT_TOGGLE:
      return {
        ...state,
        is_assembling: !state.is_assembling,
      };
    case ActionType.SET_PRODUCT_TYPE: {
      const arr = state.product_type.split(',');
      arr[action.payload.index] = action.payload.value;
      return {
        ...state,
        product_type: arr.join(','),
      };
    }
    case ActionType.SET_SPEC: {
      const arr =
        state.specs === '' ? [...Array(action.payload.dimensions)] : state.specs.split('x');
      arr[action.payload.index] = action.payload.value;
      return {
        ...state,
        specs: arr.join('x'),
      };
    }
    case ActionType.CALCULATE: {
      const weight_total = action.payload.weight * state.qty;
      const rounded_weight_total = Math.round((weight_total + Number.EPSILON) * 100) / 100;
      const total = action.payload.product_price * rounded_weight_total;
      return {
        ...state,
        weight: action.payload.weight,
        weight_total: rounded_weight_total,
        total: Math.round((total + Number.EPSILON) * 100) / 100,
      };
    }

    default:
      throw new Error();
  }
}

export function useProductWeightedFormReducer(initialState: IWeightedProductConfig) {
  return useReducer<React.Reducer<IWeightedProductConfig, Action>>(reducer, initialState);
}

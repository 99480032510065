export enum PageStatuses {
  LOADING = 'Loading...',
  ERROR = 'Error Occurs!',
  PAGE_NOT_FOUND = 'Page Not Found!',
}

interface PageStatusProps {
  status?: keyof typeof PageStatuses;
}

const PageStatus = ({ status = 'LOADING' }: PageStatusProps) => {
  return (
    <div>
      <span className="page-status">{PageStatuses[status]}</span>
    </div>
  );
};

export default PageStatus;

import { ChangeEvent, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import { pick } from 'underscore';

import { IQuotationMFO } from '../../../interfaces/quotation';

interface ICreateMFOPopup {
  togglePopup: () => void;
  popupId: string;
  onSubmitClick: (formData: ICreateMFOFormData) => Promise<void>;
  isEdit?: boolean;
  mfo?: IQuotationMFO;
}

export interface ICreateMFOFormData {
  earnest_select: 'full' | 'partial';
  earnest: number;
  shipment: 'collect' | 'delivery';
  area?: string;
  map_link?: string;
  delivery_name?: string;
  delivery_phone?: string;
  ship_date: string;
  time: string;
  comment: string;
}

export type CreateMFOFormDataKey = keyof ICreateMFOFormData;

const initialFormData: ICreateMFOFormData = {
  earnest_select: 'full',
  earnest: 0,
  shipment: 'collect',
  area: '',
  map_link: '',
  ship_date: '',
  delivery_name: '',
  delivery_phone: '',
  time: 'none',
  comment: '',
};

const CreateMFOPopup = (props: ICreateMFOPopup) => {
  const { togglePopup, popupId, onSubmitClick, mfo = initialFormData } = props;

  const [formData, setFormData] = useState<ICreateMFOFormData>({
    ...(pick(mfo, Object.keys(initialFormData)) as ICreateMFOFormData),
    earnest_select: 'is_earnest' in mfo && mfo.is_earnest ? 'partial' : 'full',
    shipment: 'is_delivery' in mfo && mfo.is_delivery ? 'delivery' : 'collect',
    ship_date: 'deliver_date' in mfo ? mfo.deliver_date : '',
  });

  const onEditInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    key: CreateMFOFormDataKey
  ) => {
    setFormData((prev) => {
      const newItem: ICreateMFOFormData = {
        ...prev,
        [key]: event.target.value,
      };
      return newItem;
    });
  };

  const onEditValueChange = (values: NumberFormatValues, key: CreateMFOFormDataKey) => {
    const { floatValue } = values;
    setFormData((prev) => {
      const newItem: ICreateMFOFormData = {
        ...prev,
        [key]: floatValue,
      };
      return newItem;
    });
  };

  const onSubmit = () => {
    console.log(formData);
    onSubmitClick(formData);
    togglePopup();
  };
  return (
    <>
      <input type="checkbox" id={popupId} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="text-lg font-bold">สร้าง MFO</h3>
          <div className="mt-4 w-full">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">ชำระแล้ว</span>
              </label>
              <select
                className="select select-bordered min-w-[12rem]"
                value={formData.earnest_select}
                onChange={(e) => onEditInputChange(e, 'earnest_select')}
              >
                <option value="full">เต็มจำนวน</option>
                <option value="partial">มัดจำ</option>
              </select>
            </div>
            {formData.earnest_select === 'partial' && (
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">มัดจำ (บาท)</span>
                </label>
                <NumericFormat
                  value={formData.earnest || ''}
                  onValueChange={(values) => onEditValueChange(values, 'earnest')}
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  className="input input-bordered w-full"
                />
              </div>
            )}
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">รับเอง / ส่ง</span>
              </label>
              <select
                className="select select-bordered min-w-[12rem]"
                value={formData.shipment}
                onChange={(e) => onEditInputChange(e, 'shipment')}
              >
                <option value="collect">รับเอง</option>
                <option value="delivery">ส่ง</option>
              </select>
            </div>
            {formData.shipment === 'delivery' && (
              <>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Area</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    value={formData.area}
                    onChange={(e) => onEditInputChange(e, 'area')}
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Map Link</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    placeholder="https://"
                    value={formData.map_link}
                    onChange={(e) => onEditInputChange(e, 'map_link')}
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">ชื่อผู้รับของ</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    placeholder="คุณ"
                    value={formData.delivery_name}
                    onChange={(e) => onEditInputChange(e, 'delivery_name')}
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">เบอร์ผู้รับของ</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered w-full"
                    placeholder="08x"
                    value={formData.delivery_phone}
                    onChange={(e) => onEditInputChange(e, 'delivery_phone')}
                  />
                </div>
              </>
            )}
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">วันที่ส่ง / วันที่รับ</span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full"
                value={formData.ship_date}
                onChange={(e) => onEditInputChange(e, 'ship_date')}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">เวลาส่ง / รับ</span>
              </label>
              <select
                className="select select-bordered min-w-[12rem]"
                value={formData.time}
                onChange={(e) => onEditInputChange(e, 'time')}
              >
                <option value="none">ไม่ระบุเวลา</option>
                <option value="morning">เช้า</option>
                <option value="afternoon">บ่าย</option>
              </select>
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Comment</span>
              </label>
              <textarea
                className="textarea textarea-bordered"
                rows={3}
                placeholder="comment"
                value={formData.comment}
                onChange={(e) => onEditInputChange(e, 'comment')}
              ></textarea>
            </div>
          </div>
          <div className="modal-action">
            <label className="btn btn-ghost" onClick={togglePopup}>
              Cancel
            </label>
            <label className="btn" onClick={onSubmit}>
              Submit
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMFOPopup;

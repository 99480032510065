import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';

import {
  ICategoryProduct,
  INormalProductConfig,
  IOtherProductConfig,
  IProductsCategoriesResponse,
  IProductType,
  IProductTypeInfo,
  IWeightedProductCalculateBody,
  IWeightedProductCalculateResponse,
  IWeightedProductConfig,
} from '../interfaces/product';
import {
  IQuotationProduct,
  IQuotationProductNormal,
  IQuotationProductOther,
  IQuotationProductWeighted,
} from '../interfaces/quotation';
import { UseQueryError } from '../interfaces/utils';

export async function fetchProductsCategories(signal?: AbortSignal) {
  const { data } = await axios.get('/products/category/', {
    signal,
  });
  return data;
}

export function useProductsCategories() {
  return useQuery<IProductsCategoriesResponse[], AxiosError>(
    ['products', 'categories'],
    ({ signal }) => fetchProductsCategories(signal)
  );
}

export async function fetchProductsByCategory(id: number) {
  const { data } = await axios.get<ICategoryProduct[]>(`/products/category/${id}/`);
  return data;
}

export function useProductsByCategory(ids: number[]) {
  const queries = ids.map((id) => {
    return {
      queryKey: ['products', 'categories', `${id}`],
      queryFn: () => fetchProductsByCategory(id),
      enabled: !!id,
    };
  });
  return useQueries({ queries });
}

export const getProductInfo = async (
  productType: IProductType,
  productId: string,
  signal?: AbortSignal
) => {
  const { data } = await axios.get(`/products/${productType}/${productId}/`, {
    signal,
  });
  return data;
};

export const fetchProduct = async (
  productType: IProductType,
  productId: string,
  signal?: AbortSignal
) => {
  const { data } = await axios.get(`/quotations/${productType}/${productId}/`, {
    signal,
  });
  return data;
};

export function useProduct(productType: IProductType, productId: string) {
  return useQuery<IQuotationProduct, UseQueryError>(
    ['products', 'type', productType, productId],
    ({ signal }) => fetchProduct(productType, productId, signal),
    {
      enabled: !!(productType && productId),
    }
  );
}

export const fetchProductTypeInfo = async (productId: number, optionId: number) => {
  const { data } = await axios.get<IProductTypeInfo[]>('/products/type/', {
    params: {
      product_id: productId,
      option_id: optionId,
    },
  });
  return data;
};

export function useProductTypeInfo(productId: number, typeAmount: number) {
  const types = useQueries(
    useMemo(
      () => ({
        queries: [...Array(typeAmount)].map((_, index) => {
          return {
            queryKey: ['products', 'type_info', `${productId}`, `${index + 1}`],
            queryFn: () => fetchProductTypeInfo(productId, index + 1),
            staleTime: Infinity,
            enabled: productId !== undefined && typeAmount !== undefined,
          };
        }),
      }),
      [productId, typeAmount]
    )
  );

  return !types.length ? [] : types.map(({ data }) => (data ? [...data] : undefined));
}

export const calculateWeightedProduct = async (
  formData: IWeightedProductCalculateBody,
  productId: number
) => {
  const { data } = await axios.post<IWeightedProductCalculateResponse>(
    `/products/weighted/${productId}/calculate/`,
    formData
  );
  return data;
};

interface IUseUpdateWeightedVars {
  formData: IWeightedProductConfig;
  productId: string;
}

export const useUpdateWeightedProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: IUseUpdateWeightedVars) =>
      axios.patch<IQuotationProductWeighted>(
        `/quotations/weighted/${variables.productId}/`,
        variables.formData
      ),
    {
      onSettled: async (data, error, variables) => {
        await queryClient.invalidateQueries(['products', 'type', 'weighted', variables.productId]);
      },
    }
  );
};

interface IUseUpdateNormalVars {
  formData: INormalProductConfig;
  productId: string;
}

export const useUpdateNormalProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: IUseUpdateNormalVars) =>
      axios.patch<IQuotationProductNormal>(
        `/quotations/normal/${variables.productId}/`,
        variables.formData
      ),
    {
      onSettled: async (data, error, variables) => {
        await queryClient.invalidateQueries(['products', 'type', 'normal', variables.productId]);
      },
    }
  );
};

interface IUseUpdateOtherVars {
  formData: IOtherProductConfig;
  productId: string;
}

export const useUpdateOtherProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: IUseUpdateOtherVars) =>
      axios.patch<IQuotationProductOther>(
        `/quotations/other/${variables.productId}/`,
        variables.formData
      ),
    {
      onSettled: async (data, error, variables) => {
        await queryClient.invalidateQueries(['products', 'type', 'other', variables.productId]);
      },
    }
  );
};

// export const fetchQuotationById = async (quotationId: string) => {
//   const { data } = await axios.get(`/quotations/${quotationId}/`);
//   return data;
// };

// export function useQuotation(quotationId: string) {
//   return useQuery<IQuotation, UseQueryError>(
//     ['quotations', quotationId],
//     () => fetchQuotationById(quotationId),
//     {
//       enabled: !!quotationId,
//     }
//   );
// }

import { useMatch } from '@tanstack/react-location';
import { format } from 'date-fns';
import { useRef } from 'react';

import QuotationProductNormalRow from '../../components/Quotation/Print/QuotationProductNormalRow';
import QuotationProductOtherRow from '../../components/Quotation/Print/QuotationProductOtherRow';
import QuotationProductWeightedRow from '../../components/Quotation/Print/QuotationProductWeightedRow';
import { useQuotation, useQuotationProductPrint } from '../../hooks/quotation';
import {
  IQuotationMFOPrintWeightedItem,
  IQuotationPrintNormalItem,
  IQuotationPrintOtherItem,
} from '../../interfaces/quotation';
import { formatTaxId, numberFormatter } from '../../utils/formatter';

const QuotationPreviewMFO = () => {
  const {
    params: { quotationId },
  } = useMatch();

  const { data: quotation } = useQuotation(quotationId);
  const { data: quotationPrint } = useQuotationProductPrint(quotationId);

  const ref = useRef<HTMLDivElement>(null);

  const customerPrefix = (prefix: 'mr' | 'engineer' | 'org' | 'other', prefixOther?: string) => {
    const mapping = {
      mr: 'คุณ',
      engineer: 'ช่าง',
      org: 'บจก.',
    };
    if (prefix === 'other') {
      return prefixOther;
    } else {
      return mapping[prefix];
    }
  };

  return (
    <>
      <div className="mx-auto max-w-[980px] space-y-4 px-4">
        {quotation && quotationPrint && (
          <div>
            <div ref={ref} className="bg-white p-4">
              <style>
                {`
                @media print {
                  html {
                    font-size: 12px;
                    padding: 3rem;
                  }
                }
              `}
              </style>
              <div className="page-container space-y-4 bg-white font-sarabun">
                <div className="flex space-x-6">
                  <div className="flex-1 flex-shrink-0">
                    <ul className="flex flex-wrap gap-1">
                      {Object.entries(quotationPrint.used_product_type).map(([key, value]) => (
                        <li
                          key={key}
                          style={{
                            backgroundColor: `#${value}`,
                          }}
                          className="px-3 py-2"
                        >
                          {key}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="flex items-center space-x-2 text-right">
                      <div className="flex-1">
                        <span className="text-2xl">ใบเสนอราคา</span>
                      </div>
                      <div className="w-[5rem] text-[#3989c6]">
                        <span>{quotation.running_number}</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 text-right">
                      <div className="flex-1">
                        <span>วันที่</span>
                      </div>
                      <div className="w-[5rem]">
                        <span>{format(new Date(quotation.create_date), 'dd/MM/yyyy')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <h2 className="text-center text-2xl font-bold">ใบเสนอราคา</h2>
                <div className="grid grid-cols-2 border border-black p-4">
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col">
                      <span>เรียน / Attention:</span>
                      <span className="text-2xl font-bold">
                        {customerPrefix(quotation.prefix, quotation.prefix_other)} {quotation.name}
                      </span>
                    </div>
                    <div className="flex space-x-2">
                      <span>โทร / Tel:</span>
                      <span className="flex-1">{quotation.phone}</span>
                    </div>
                  </div>
                  <div>
                    {quotation.note ? (
                      <>
                        <div className="flex space-x-2">
                          <span>หมายเหตุ:</span>
                        </div>
                        <div>
                          <span className="flex-1 text-2xl">{quotation.note}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="hidden flex-col">
                    <div className="flex space-x-2">
                      <span className="">เลขประจำตัวผู้เสียภาษี</span>
                      <span className="flex-1">{formatTaxId(quotation.tax_id) || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">บริษัท</span>
                      <span className="flex-1">{quotation.business_name || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">สาขา</span>
                      <span className="flex-1">{quotation.branch || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">ที่อยู่</span>
                      <span className="flex-1">{quotation.address || '-'}</span>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="quotation-table">
                  <table className="w-full bg-[#eeeeee]">
                    <thead className="text-center text-[0.85em]">
                      <tr>
                        <th className="w-[2.25rem]">
                          <span className="block">
                            ลำดับ <br />
                            ITEM
                          </span>
                        </th>
                        <th className="w-[100%]">
                          <span className="block">
                            รายการ <br />
                            DESCRIPTION
                          </span>
                        </th>
                        <th className="w-[7.5%]">
                          <span className="block">
                            จำนวน <br />
                            QUANTITY
                          </span>
                        </th>
                        <th className="w-[10%]">
                          <span className="block">นน. ต่อชิ้น</span>
                        </th>
                        <th className="w-[10%]">
                          <span className="block">
                            น้ำหนักรวม <br />
                            WEIGHT
                          </span>
                        </th>
                        <th className="w-[2.5%]">
                          <span className="block">เหล็ก</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-base">
                      {quotationPrint.items.map((item, index) => {
                        if (item.type === 'weighted_product') {
                          return (
                            <QuotationProductWeightedRow
                              priceShow={true}
                              key={`${item.type}_${index}`}
                              item={item as IQuotationMFOPrintWeightedItem}
                              index={index}
                              isMFO={true}
                            />
                          );
                        }
                        if (item.type === 'normal_product') {
                          return (
                            <QuotationProductNormalRow
                              priceShow={true}
                              key={`${item.type}_${index}`}
                              item={item as unknown as IQuotationPrintNormalItem}
                              index={index}
                            />
                          );
                        }
                        return (
                          <QuotationProductOtherRow
                            priceShow={true}
                            key={`${item.type}_${index}`}
                            item={item as unknown as IQuotationPrintOtherItem}
                            index={index}
                          />
                        );
                      })}
                      <tr className="quotation-table-footer">
                        <td colSpan={2}></td>
                        <td colSpan={2} className="!border-gray-300">
                          น้ำหนักรวม
                        </td>
                        <td className="!border-gray-300 font-bold">
                          {numberFormatter(2).format(quotationPrint.net_weight)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="ml-auto w-full max-w-md space-y-4 border-x-4 border-[#3989c6] px-6 py-1 text-xl">
                  <p>
                    <span className="font-bold">เงื่อนไข:</span> ชำระเต็มจำนวนก่อนผลิตสินค้า
                  </p>
                  <p>กรุณาโอนมาที่บัญชีเลขที่: บจก.เหล็กร่มเกล้า</p>
                  <p className="text-2xl">กสิกรไทย 010-1-58833-5</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuotationPreviewMFO;

import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLoadRoute, useMatch } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { useDeleteProduct, useQuotationProducts } from '../../hooks/quotation';
import { IProductType } from '../../interfaces/product';
import ConfirmationToggle from '../Popup/Confirmation/ConfirmationToggle';
import ProductImage from './ProductImage';

const CartTable = () => {
  const {
    params: { quotationId },
  } = useMatch();
  const loadRoute = useLoadRoute();

  const { data: products, isLoading: isProductsLoading } = useQuotationProducts(quotationId);
  const mutation = useDeleteProduct();

  const onDeleteProduct = async (productType: IProductType, productId: number) => {
    try {
      await mutation.mutateAsync({
        productId,
        productType,
        quotationId,
      });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };
  return (
    <div className="w-full overflow-x-auto rounded-lg border border-gray-200">
      <table className="cart-table table w-full">
        <thead>
          <tr>
            <th className="w-[80px] text-sm">รูป</th>
            <th className="text-sm">ชื่อ</th>
            <th></th>
            <th className="text-sm">จำนวน</th>
            <th className="text-sm">หน่วย</th>
            <th className="text-sm">ผูก</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isProductsLoading ? (
            <tr>
              <td colSpan={7}>Loading...</td>
            </tr>
          ) : products && products.length > 0 ? (
            <>
              {products.map((product) => (
                <tr key={`${product.type}_${product.id}`}>
                  <th>
                    <ProductImage product={product} />
                  </th>
                  <td>
                    <span className="block">{product.product__name}</span>
                    <span className="block text-sm">{product.specs}</span>
                  </td>
                  <td>
                    <span></span>
                  </td>
                  <td>
                    <span>{product.qty}</span>
                  </td>
                  <td>
                    <span>{product.product__unit}</span>
                  </td>
                  <td>
                    <span>
                      {product.type === IProductType.WEIGHTED && product.is_assembling ? (
                        <span className="badge badge-warning badge-lg font-bold">ผูก</span>
                      ) : (
                        ''
                      )}
                    </span>
                  </td>
                  <th className="space-x-2 text-right">
                    <>
                      <Link
                        className="btn btn-square btn-xs"
                        to={`/quotations/${quotationId}/${product.type}/${product.id}`}
                        onMouseEnter={() =>
                          loadRoute({
                            to: `/quotations/${quotationId}/${product.type}/${product.id}`,
                          })
                        }
                      >
                        <PencilSquareIcon className="h-4 w-4" />
                      </Link>
                      <ConfirmationToggle
                        onSubmitClick={() => {
                          onDeleteProduct(product.type, product.id);
                        }}
                        popupId="confirmation_popup"
                        className="btn btn-error btn-square btn-xs text-white"
                        title="ยืนยันการลบ"
                        submitLabel="ลบ"
                        submitButtonColor="error"
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </ConfirmationToggle>
                    </>
                  </th>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                ไม่มีสินค้าในตะกร้า
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CartTable;

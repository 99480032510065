import { useReducer } from 'react';

export enum ActionType {
  INPUT_CHANGE = 'input_change',
}

type Action = {
  type: ActionType.INPUT_CHANGE;
  payload: {
    name: string;
    value: number | string;
  };
};

function reducer<T>(state: T, action: Action): T {
  switch (action.type) {
    case ActionType.INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    default:
      throw new Error();
  }
}

export function useProductFormReducer<T>(initialState: T) {
  return useReducer<React.Reducer<T, Action>>(reducer, initialState);
}

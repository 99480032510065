import { useSearch } from '@tanstack/react-location';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';

import { fetchInvoiceByNo, importQuotation } from '../../../hooks/invoice';
import { IInvoiceItem } from '../../../interfaces/invoice';
import { RouteLocationGenerics } from '../../../routes';

interface IImportQuotationPopup {
  togglePopup: () => void;
  popupId: string;
  invoiceNo: string;
  setItems: (items: IInvoiceItem[]) => void;
  setTotal: (value: number) => void;
  setVat: (value: number) => void;
  setInvoiceAmount: (value: number) => void;
}

const ImportQuotationPopup = (props: IImportQuotationPopup) => {
  const { popupId, togglePopup, invoiceNo, setItems, setTotal, setVat, setInvoiceAmount } = props;

  const search = useSearch<RouteLocationGenerics>();
  const [quotationNo, setQuotationNo] = useState(search.quotationId || '');
  const [isImporting, setIsImporting] = useState<boolean>(false);

  const onQuotationNoChange = (values: NumberFormatValues) => {
    const { value } = values;
    setQuotationNo(value);
  };

  const importData = async () => {
    setIsImporting(true);
    await importQuotation(invoiceNo, quotationNo);
    const data = await fetchInvoiceByNo(invoiceNo);
    setItems(data.items);
    setTotal(data.total);
    setVat(data.vat);
    setInvoiceAmount(data.invoiceAmount);
    togglePopup();
  };
  return (
    <>
      <input type="checkbox" id={popupId} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="text-lg font-bold">Import Quotation</h3>
          <div className="w-full">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">เลขใบเสนอราคา</span>
              </label>
              <PatternFormat
                value={quotationNo}
                onValueChange={onQuotationNoChange}
                displayType="input"
                type="text"
                format="#######"
                valueIsNumericString={true}
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="modal-action">
            <label className="btn btn-ghost" onClick={togglePopup}>
              Cancel
            </label>
            <button className="btn" disabled={isImporting} onClick={importData}>
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportQuotationPopup;

import InvoicesTable from '../../components/Invoice/InvoicesTable';
import NewInvoiceToggle from '../../components/Popup/NewInvoice/NewInvoiceToggle';

const Invoices = () => {
  return (
    <>
      <div className="space-y-2">
        <div className="flex justify-end space-x-2">
          <button className="btn">All</button>
          <div>
            <NewInvoiceToggle />
          </div>
        </div>
        <InvoicesTable from="" to="" />
      </div>
    </>
  );
};

export default Invoices;

import { useState } from 'react';
import { useCookies } from 'react-cookie';

import { useCustomers } from '../../hooks/customer';
import CustomerCard from './CustomerCard';

const SearchCustomer = () => {
  const [cookies, setCookie] = useCookies(['customer-filter']);

  const [filter, setFilter] = useState(cookies['customer-filter'] || '');
  const { data, error, isError } = useCustomers(filter);
  return (
    <div className="space-y-4">
      <div className="form-control w-full">
        <label className="label">
          <span className="label-text">ค้นหาข้อมูลลูกค้า (ชื่อหรือเบอร์โทร)</span>
        </label>
        <input
          type="text"
          placeholder="ค้นหา"
          className="input input-bordered w-full"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
            setCookie('customer-filter', e.target.value, {
              path: '/',
            });
          }}
        />
      </div>
      <div className="">
        {!filter ? (
          <p className="select-none text-center text-gray-400">Please search something...</p>
        ) : isError ? (
          <span>Error: {error.message}</span>
        ) : data && data.length > 0 ? (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {data.map((customer) => (
              <CustomerCard data={customer} key={customer.id} />
            ))}
          </div>
        ) : (
          <p className="select-none text-center text-gray-400">No customer found, try again!</p>
        )}
      </div>
    </div>
  );
};

export default SearchCustomer;

import { IQuotationPrintOtherItem } from '../../../interfaces/quotation';
import { numberFormatter } from '../../../utils/formatter';

interface IQuotationProductOtherRowProps {
  priceShow: boolean;
  item: IQuotationPrintOtherItem;
  index: number;
  isMFO?: boolean;
  isDelivery?: boolean;
}

const QuotationProductOtherRow = (props: IQuotationProductOtherRowProps) => {
  const { priceShow, item, index, isMFO = false, isDelivery = false } = props;
  return (
    <tr>
      <td>{index + 1}</td>
      <td className="text-lg text-[#3989c6]">
        <span>{item.name}</span>
        {item.note && <span className="text-base"> ({item.note})</span>}
      </td>
      <td className="text-right">{item.qty}</td>
      <td></td>
      <td></td>
      {!isDelivery && !isMFO && (
        <>
          <td className="text-right">{priceShow ? numberFormatter(2).format(item.price) : ''}</td>
          <td className="text-right">
            {priceShow ? numberFormatter(2).format(item.total_price) : ''}
          </td>
          {isMFO && <td></td>}
        </>
      )}
    </tr>
  );
};

export default QuotationProductOtherRow;

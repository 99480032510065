import { ArrowDownTrayIcon, ArrowLeftIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import { Link, useMatch } from '@tanstack/react-location';
import { format, parse } from 'date-fns';
import { ChangeEvent, useEffect, useState } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import { toast } from 'react-toastify';

import EtaxDownloadButton from '../../components/Invoice/EtaxDownloadButton';
import EtaxUploadButton from '../../components/Invoice/EtaxUploadButton';
import InvoicePrintButton from '../../components/Invoice/InvoicePrintButton';
import InvoiceTable from '../../components/Invoice/InvoiceTable';
import PageStatus from '../../components/PageStatus';
import ImportQuotationToggle from '../../components/Popup/ImportQuotation/ImportQuotationToggle';
import { updateInvoice, useInvoice } from '../../hooks/invoice';
import { IInvoiceItem } from '../../interfaces/invoice';
import { RouteLocationGenerics } from '../../routes';

const Invoice = () => {
  const {
    params: { invoiceNo },
  } = useMatch<RouteLocationGenerics>();

  const { status, data } = useInvoice(invoiceNo);
  const [items, setItems] = useState<IInvoiceItem[]>([]);
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [taxId, setTaxId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyAddr, setCompanyAddr] = useState('');
  const [branch, setBranch] = useState('');
  const [note, setNote] = useState('');
  const [clientNote, setClientNote] = useState('');
  const [etaxPdf, setEtaxPdf] = useState('');
  const [etaxXml, setEtaxXml] = useState('');
  const [email, setEmail] = useState('');
  const [total, setTotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [printed, setPrinted] = useState(false);
  const [checked, setChecked] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [bankAmount, setBankAmount] = useState(0);

  useEffect(() => {
    if (data) {
      setItems(data.items);
      setDate(format(parse(data.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd'));
      setTaxId(data.taxId);
      setCompanyName(data.name);
      setCompanyAddr(data.address);
      setBranch(data.branch);
      setTotal(data.total);
      setVat(data.vat);
      setNote(data.note);
      setInvoiceAmount(data.invoiceAmount);
      setBankAmount(data.bankAmount);
      setEmail(data.email);
      setPrinted(data.printed);
      setChecked(data.checked);
      setClientNote(data.clientNote);
      setEtaxPdf(data.etaxPdf);
      setEtaxXml(data.etaxXml);
    }
  }, [data]);

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const onTaxIdChange = (values: NumberFormatValues) => {
    setTaxId(values.value);
  };

  const onCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
  };

  const onCompanyAddrChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyAddr(event.target.value);
  };

  const onBranchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBranch(event.target.value);
  };

  const onNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const onClientNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setClientNote(event.target.value);
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const onBankAmountChange = (values: NumberFormatValues) => {
    setBankAmount(values.floatValue || 0);
  };

  const onPrintedChange = () => {
    setPrinted(!printed);
  };

  const onCheckedChange = () => {
    setChecked(!checked);
  };

  const handleDownload = () => {
    window.location.href = etaxPdf;
  };

  const onSaveInvoice = async () => {
    const data = await updateInvoice(
      invoiceNo,
      taxId,
      companyName,
      companyAddr,
      branch,
      date,
      bankAmount,
      note,
      clientNote,
      printed,
      checked,
      email
    );
    setTotal(data.data.total);
    setVat(data.data.vat);
    setInvoiceAmount(data.data.invoiceAmount);
    toast.success('บันทึกสำเร็จ', {
      draggable: true,
    });
  };

  return (
    <>
      {!invoiceNo || status === 'loading' ? (
        <PageStatus />
      ) : status === 'error' ? (
        <PageStatus status="ERROR" />
      ) : data ? (
        <div className="space-y-4">
          <Link
            // to={'/invoices'}
            to=".."
            preload={2000}
            className="btn btn-ghost gap-2 bg-white shadow-lg"
          >
            <ArrowLeftIcon className="h-5 w-5" />
            Back
          </Link>
          {/* Navbar */}
          <div className="navbar rounded-box bg-base-100 shadow-xl">
            <div className="navbar-start">
              <ImportQuotationToggle
                invoiceNo={invoiceNo}
                setItems={setItems}
                setTotal={setTotal}
                setVat={setVat}
                setInvoiceAmount={setInvoiceAmount}
              />
            </div>
            <div className="navbar-end space-x-2">
              {!etaxPdf ? <EtaxUploadButton invoiceInfo={data} items={items} email={email} /> : ''}
              {!etaxPdf ? (
                <EtaxDownloadButton invoiceInfo={data} items={items} etaxPdf={etaxPdf} />
              ) : (
                ''
              )}
              {etaxPdf ? (
                <button className="btn gap-2" onClick={handleDownload}>
                  <span className="hidden sm:inline">Etax Copy</span>
                  <ArrowDownTrayIcon className="h-5 w-5" />
                </button>
              ) : (
                ''
              )}

              <InvoicePrintButton invoiceInfo={data} items={items} />
              <button className="btn gap-2" onClick={onSaveInvoice}>
                <span className="hidden sm:inline">Save</span>
                <DocumentCheckIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          {/* Content */}
          <div
            className={
              checked
                ? 'rounded-box space-y-8 bg-red-400 px-8 py-4 shadow-lg'
                : 'rounded-box space-y-8 bg-base-100 px-8 py-4 shadow-lg'
            }
          >
            <div className="w-full space-y-2">
              <div className="flex items-center space-x-2 text-right">
                <div className="flex-1">
                  <span>เลขที่</span>
                </div>
                <div className="w-48">
                  <span>{data.invoiceNo}</span>
                </div>
              </div>
              <div className="space-y-2 sm:flex sm:space-y-0">
                <div className="flex w-full items-center space-x-2">
                  <input
                    type="checkbox"
                    className="input input-bordered input-sm w-4"
                    onChange={onPrintedChange}
                    checked={printed}
                  />
                  <span className="mr-10">Printed</span>
                  <input
                    type="checkbox"
                    className="input input-bordered input-sm w-4"
                    onChange={onCheckedChange}
                    checked={checked}
                  />
                  <span>Nui Check</span>
                </div>
                <div className="w-full items-center sm:flex sm:space-x-2">
                  <div className="text-right sm:w-24">
                    <span>ยอดเงินฝาก</span>
                  </div>
                  <div className="flex-1">
                    <NumericFormat
                      type="text"
                      className="input input-bordered input-sm w-full"
                      value={bankAmount}
                      onValueChange={onBankAmountChange}
                    />
                  </div>
                </div>
                <div className="items-center sm:flex sm:space-x-2 sm:text-right">
                  <div className="sm:w-56">
                    <span>วันที่</span>
                  </div>
                  <div className="sm:w-48">
                    <input
                      type="date"
                      className="input input-bordered input-sm w-full"
                      value={date}
                      onChange={onDateChange}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-2 sm:flex sm:space-y-0">
                <div className="w-full items-center sm:flex sm:space-x-2">
                  <div className="sm:w-24">
                    <span>นามผู้ซื้อ</span>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="input input-bordered input-sm w-full"
                      value={companyName}
                      onChange={onCompanyNameChange}
                    />
                  </div>
                </div>
                <div className="w-full items-center sm:flex sm:space-x-2">
                  <div className="text-right sm:w-24">
                    <span>สาขา</span>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="input input-bordered input-sm w-full"
                      value={branch}
                      onChange={onBranchChange}
                    />
                  </div>
                </div>
                <div className="items-center sm:flex sm:space-x-2 sm:text-right">
                  <div className="sm:w-56">
                    <span>เลขประจำตัวผู้เสียภาษี</span>
                  </div>
                  <div className="sm:w-48">
                    <PatternFormat
                      value={taxId}
                      onValueChange={onTaxIdChange}
                      displayType="input"
                      type="text"
                      valueIsNumericString={true}
                      format="#-####-#####-##-#"
                      className="input input-bordered input-sm w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="items-center sm:flex sm:space-x-2">
                <div className="w-full items-center sm:flex sm:space-x-2">
                  <div className="sm:w-24">
                    <span>ที่อยู่</span>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="input input-bordered input-sm w-full"
                      value={companyAddr}
                      onChange={onCompanyAddrChange}
                    />
                  </div>
                </div>
                <div className="w-full items-center sm:flex sm:space-x-2">
                  <div className="sm:w-24">
                    <span>Email</span>
                  </div>
                  <div className="flex-1">
                    <input
                      type="text"
                      className="input input-bordered input-sm w-full"
                      value={email}
                      onChange={onEmailChange}
                    />
                  </div>
                </div>
              </div>
              <div className="items-center sm:flex sm:space-x-2">
                <div className="sm:w-24">
                  <span>Note</span>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    className={
                      note === ''
                        ? 'input input-bordered input-sm w-full'
                        : 'input input-bordered input-sm w-full bg-red-400'
                    }
                    value={note}
                    onChange={onNoteChange}
                  />
                </div>
              </div>
              <div className="items-center sm:flex sm:space-x-2">
                <div className="sm:w-24">
                  <span>หมายเหตุ</span>
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    className="input input-bordered input-sm w-full"
                    value={clientNote}
                    onChange={onClientNoteChange}
                  />
                </div>
              </div>
            </div>
            {/* Form Table */}
            <InvoiceTable
              items={items}
              setItems={setItems}
              total={total}
              vat={vat}
              invoiceAmount={invoiceAmount}
              invoiceNo={invoiceNo}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Invoice;

import { useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { pick } from 'underscore';

import { useUpdateOtherProduct } from '../../hooks/product';
import { IOtherProductConfig, IProductCalculationTypes } from '../../interfaces/product';
import { IQuotationProductOther } from '../../interfaces/quotation';
import { IProductBaseProps } from '../../pages/Product/Product';
import { ActionType, useProductFormReducer } from '../../reducers/productFormReducer';
import { numberFormatter } from '../../utils/formatter';

interface IProductOtherFormProps extends IProductBaseProps {
  data: IQuotationProductOther;
}

const productConfigKeys = ['qty', 'option_note', 'option_value'];

const ProductOtherForm = (props: IProductOtherFormProps) => {
  const { data, productId, quotationId } = props;
  const { product } = data;
  const initialConfig = pick(data, productConfigKeys) as IOtherProductConfig;

  const navigate = useNavigate();

  const [state, dispatch] = useProductFormReducer(initialConfig);

  const mutation = useUpdateOtherProduct();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.INPUT_CHANGE,
      payload: {
        name: event.target.name,
        value: event.target.value,
      },
    });
  };

  const onSave = async () => {
    try {
      await mutation.mutateAsync({ formData: state, productId });
      navigate({ to: `/quotations/${quotationId}` });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };
  return (
    <div className="flex flex-col items-center space-y-4">
      <figure className="max-w-xs">
        <>
          {product.calculation_type === IProductCalculationTypes.PERCENT ? (
            <svg
              className="w-24 fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
            </svg>
          ) : (
            <svg
              className="w-24 fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M176 32V64C237.9 64 288 114.1 288 176C288 200.2 280.3 222.6 267.3 240.9C298.9 260.7 320 295.9 320 336C320 397.9 269.9 448 208 448H176V480C176 497.7 161.7 512 144 512C126.3 512 112 497.7 112 480V448H41.74C18.69 448 0 429.3 0 406.3V101.6C0 80.82 16.82 64 37.57 64H112V32C112 14.33 126.3 0 144 0C161.7 0 176 14.33 176 32V32zM112 128H64V224H112V128zM224 176C224 149.5 202.5 128 176 128V224C202.5 224 224 202.5 224 176zM112 288H64V384H112V288zM208 384C234.5 384 256 362.5 256 336C256 309.5 234.5 288 208 288H176V384H208z" />
            </svg>
          )}
        </>
      </figure>
      <h2 className="text-xl font-bold">{product.name}</h2>
      <div className="w-full space-y-2">
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">มูลค่า</span>
          </label>
          <input
            type="number"
            placeholder="ex. 10"
            className="input input-bordered w-full"
            value={state['option_value'] || ''}
            name="option_value"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">จำนวน</span>
          </label>
          <input
            type="number"
            placeholder="ex. 10"
            className="input input-bordered w-full"
            value={state['qty'] || ''}
            name="qty"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">Note</span>
          </label>
          <input
            type="text"
            placeholder="..."
            className="input input-bordered w-full"
            value={state['option_note']}
            name="option_note"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
      </div>
      <div className="rounded-box w-full space-y-2 bg-base-300 p-4">
        <div className="flex space-x-2">
          <span className="flex-shrink-0 font-bold">สรุปส่วนลด</span>
          <span className="w-full text-right">
            {numberFormatter(2).format(state.option_value * state.qty)}
          </span>
          <span className="text-right">{product.calculation_type === 'percent' ? '%' : 'บาท'}</span>
        </div>
      </div>
      <div className="flex w-full space-x-2">
        <button className="btn btn-block" onClick={onSave}>
          บันทึก
        </button>
      </div>
    </div>
  );
};

export default ProductOtherForm;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { IStaff } from '../interfaces/quotation';
import { UseQueryError } from '../interfaces/utils';

export async function fetchStaffs() {
  await new Promise((r) => setTimeout(r, 500));
  const { data } = await axios.get('/utils/staffs/');
  return data;
}

export function useStaffs() {
  return useQuery<IStaff[], UseQueryError>(['staffs'], fetchStaffs);
}

import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import NewInvoicePopup from './NewInvoicePopup';

const NewInvoiceToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupId = 'new_invoice_popup';
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <label className="btn gap-2" htmlFor={popupId} onClick={togglePopup}>
        สร้าง
        <PlusSmallIcon className="h-5 w-5" />
      </label>
      {isOpen && (
        <>
          <NewInvoicePopup popupId={popupId} togglePopup={togglePopup} />
        </>
      )}
    </>
  );
};

export default NewInvoiceToggle;

import {
  ArrowDownOnSquareIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChangeEvent, SetStateAction, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';

import { deleteInvoiceItem, updateInvoiceItem } from '../../hooks/invoice';
import { IInvoiceItem } from '../../interfaces/invoice';
import { numberFormatter } from '../../utils/formatter';
import { ArabicNumberToText } from '../../utils/thaibath';

interface IInvoiceTable {
  items: IInvoiceItem[];
  setItems: (value: SetStateAction<IInvoiceItem[]>) => void;
  total: number;
  vat: number;
  invoiceAmount: number;
  invoiceNo: string;
}

const initialEditingRow = {
  uuid: '',
  name: '',
  type: '',
  unit: '',
  amount: 0,
  pricePerUnit: 0,
  discount: 0,
  price: 0,
  productType: '',
  productId: 0,
  steelType: '',
};

const InvoiceTable = (props: IInvoiceTable) => {
  const { items, setItems, total, vat, invoiceAmount, invoiceNo } = props;
  const [editingRow, setEditingRow] = useState<IInvoiceItem>({ ...initialEditingRow });
  const [isEditing, setIsEditing] = useState(false);

  const onEditClick = (uuid: string) => {
    setIsEditing(true);
    setEditingRow(() => {
      const current = items.filter((item) => {
        return item.uuid === uuid;
      })[0];
      return {
        ...current,
      };
    });
  };

  const onEditInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: 'uuid' | 'name' | 'steelType' | 'unit' | 'amount' | 'pricePerUnit' | 'discount' | 'price'
  ) => {
    setEditingRow((prev) => {
      const newItem: IInvoiceItem = {
        ...prev,
        [key]: event.target.value,
      };
      return newItem;
    });
  };

  const onEditValueChange = (
    values: NumberFormatValues,
    key: 'uuid' | 'name' | 'steelType' | 'unit' | 'amount' | 'pricePerUnit' | 'discount' | 'price'
  ) => {
    const { floatValue } = values;
    setEditingRow((prev) => {
      const newItem: IInvoiceItem = {
        ...prev,
        [key]: floatValue,
      };
      return newItem;
    });
  };

  const onDeleteClick = async (uuid: string) => {
    await deleteInvoiceItem(invoiceNo, uuid);
    const newItems = items.filter((data) => data.uuid != uuid);
    setItems(newItems);
  };

  const onSaveEdit = async (uuid: string) => {
    const editedItemIndex = items.findIndex((item) => {
      return item.uuid === uuid;
    });
    const newData = editingRow;
    newData.price = editingRow.amount * editingRow.pricePerUnit - editingRow.discount;
    await updateInvoiceItem(invoiceNo, uuid, newData);
    setItems((prev) => {
      const newItems = [...prev];
      newItems[editedItemIndex] = {
        ...newData,
      };
      return newItems;
    });
    setIsEditing(false);
    setEditingRow({ ...initialEditingRow });
  };

  const onCancleEdit = () => {
    setIsEditing(false);
    setEditingRow({ ...initialEditingRow });
  };

  return (
    <div className="w-full overflow-x-auto rounded-lg border border-gray-200">
      {/* <div className="w-full overflow-x-auto rounded-lg outline outline-1 outline-gray-200"> */}
      <table className="table w-full">
        <thead>
          <tr className="text-center">
            <th className="text-sm">ลำดับที่</th>
            <th className="w-1/3 min-w-[16rem] text-sm">รายการ</th>
            <th className="min-w-[5rem] text-sm">ประเภทเหล็ก</th>
            <th className="min-w-[5rem] text-sm">หน่วย</th>
            <th className="min-w-[8rem] text-sm">จำนวน</th>
            <th className="min-w-[8rem] text-sm">ราคาต่อหน่วย</th>
            <th className="min-w-[8rem] text-sm">ส่วนลด</th>
            <th className="min-w-[10rem] text-sm">จำนวนเงิน</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => (
            <tr key={item.uuid}>
              <th className="text-center">{idx + 1}</th>
              <td>
                <input
                  type="text"
                  className="input input-bordered input-sm w-full"
                  disabled={editingRow.uuid !== item.uuid}
                  value={editingRow.uuid === item.uuid ? editingRow.name : item.name}
                  onChange={(e) => onEditInputChange(e, 'name')}
                />
              </td>
              <td className="text-center">
                <input
                  type="text"
                  className="input input-bordered input-sm w-full"
                  disabled={editingRow.uuid !== item.uuid}
                  value={editingRow.uuid === item.uuid ? editingRow.steelType : item.steelType}
                  onChange={(e) => onEditInputChange(e, 'steelType')}
                />
              </td>
              <td className="text-center">
                <input
                  type="text"
                  className="input input-bordered input-sm w-full"
                  disabled={editingRow.uuid !== item.uuid}
                  value={editingRow.uuid === item.uuid ? editingRow.unit : item.unit}
                  onChange={(e) => onEditInputChange(e, 'unit')}
                />
              </td>
              <td className="text-center">
                <NumericFormat
                  value={editingRow.uuid === item.uuid ? editingRow.amount : item.amount}
                  onValueChange={(values) => onEditValueChange(values, 'amount')}
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  decimalScale={item.type === 'weighted' ? 4 : 0}
                  fixedDecimalScale={true}
                  disabled={editingRow.uuid !== item.uuid}
                  className="input input-bordered input-sm w-full text-right"
                />
              </td>
              <td className="text-right">
                <NumericFormat
                  value={
                    editingRow.uuid === item.uuid ? editingRow.pricePerUnit : item.pricePerUnit
                  }
                  onValueChange={(values) => onEditValueChange(values, 'pricePerUnit')}
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={editingRow.uuid !== item.uuid}
                  className="input input-bordered input-sm w-full text-right"
                />
              </td>
              <td className="text-right">
                <NumericFormat
                  value={editingRow.uuid === item.uuid ? editingRow.discount : item.discount}
                  onValueChange={(values) => onEditValueChange(values, 'discount')}
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={editingRow.uuid !== item.uuid}
                  className="input input-bordered input-sm w-full text-right"
                />
              </td>
              <td className="text-right">
                <NumericFormat
                  value={editingRow.uuid === item.uuid ? editingRow.price : item.price}
                  onValueChange={(values) => onEditValueChange(values, 'price')}
                  displayType="input"
                  type="text"
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={editingRow.uuid !== item.uuid}
                  className="input input-bordered input-sm w-full text-right"
                />
              </td>
              <td className="space-x-2 text-right">
                {isEditing && editingRow.uuid === item.uuid ? (
                  <>
                    <button className="btn btn-square btn-xs" onClick={() => onSaveEdit(item.uuid)}>
                      <ArrowDownOnSquareIcon className="h-4 w-4" />
                    </button>
                    <button
                      className="btn btn-error btn-square btn-xs text-white"
                      onClick={onCancleEdit}
                    >
                      <XMarkIcon className="h-4 w-4" />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-square btn-xs"
                      onClick={() => onEditClick(item.uuid)}
                    >
                      <PencilSquareIcon className="h-4 w-4" />
                    </button>
                    <button
                      className="btn btn-error btn-square btn-xs text-white"
                      onClick={() => onDeleteClick(item.uuid)}
                    >
                      <TrashIcon className="h-4 w-4" />
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td rowSpan={3} colSpan={4} className="text-center align-bottom">
              ({ArabicNumberToText(numberFormatter(2).format(total))})
            </td>
            <td colSpan={2} className="border-x">
              จำนวนเงินรวม
            </td>
            <td className="text-right">{numberFormatter(2).format(invoiceAmount)}</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={2} className="border-x">
              ภาษีมูลค่าเพิ่ม 7%
            </td>
            <td className="text-right">{numberFormatter(2).format(vat)}</td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={2} className="border-x">
              รวมทั้งสิ้น
            </td>
            <td className="text-right">{numberFormatter(2).format(total)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { IInvoice, IInvoiceItem } from '../../interfaces/invoice';
import Etax from './Etax';

interface Props {
  invoiceInfo: IInvoice;
  items: IInvoiceItem[];
  etaxPdf: string;
}

const EtaxDownloadButton = (props: Props) => {
  const { invoiceInfo, items, etaxPdf } = props;
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: invoiceInfo.invoiceNo,
    removeAfterPrint: true,
  });
  return (
    <div>
      <button className="btn gap-2" onClick={handlePrint}>
        <span className="hidden sm:inline">Etax Download</span>
        <ArrowDownTrayIcon className="h-5 w-5" />
      </button>
      <div className="hidden">
        {/* <div> */}
        <Etax ref={componentRef} invoiceInfo={invoiceInfo} items={items} />
      </div>
    </div>
  );
};

export default EtaxDownloadButton;

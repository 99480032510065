import { PrinterIcon } from '@heroicons/react/24/outline';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { format } from 'date-fns';
import { toPng } from 'html-to-image';
import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import CreateMFOToggle from '../../components/Popup/CreateMFO/CreateMFOToggle';
import QuotationProductNormalRow from '../../components/Quotation/Print/QuotationProductNormalRow';
import QuotationProductOtherRow from '../../components/Quotation/Print/QuotationProductOtherRow';
import QuotationProductWeightedRow from '../../components/Quotation/Print/QuotationProductWeightedRow';
import { useAddMFO, useQuotation, useQuotationProductPrint } from '../../hooks/quotation';
import logo from '../../images/rk-logo.png';
import {
  IQuotationPrintNormalItem,
  IQuotationPrintOtherItem,
  IQuotationPrintWeightedItem,
} from '../../interfaces/quotation';
import { formatTaxId, numberFormatter } from '../../utils/formatter';

const QuotationPrint = () => {
  const {
    params: { quotationId },
  } = useMatch();
  const navigate = useNavigate();

  const { data: quotation } = useQuotation(quotationId);
  const { data: quotationPrint } = useQuotationProductPrint(quotationId);
  const { mutateAsync: addMFO } = useAddMFO();

  const ref = useRef<HTMLDivElement>(null);
  const [image, setImage] = useState('');
  const [isQuotation, setIsQuotation] = useState(true);
  const [priceShow, setPriceShow] = useState(true);

  const reactToPrintContent = useCallback(() => {
    return ref.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: quotationId,
    removeAfterPrint: true,
  });

  const customerPrefix = (prefix: 'mr' | 'engineer' | 'org' | 'other', prefixOther?: string) => {
    const mapping = {
      mr: 'คุณ',
      engineer: 'ช่าง',
      org: 'บจก.',
    };
    if (prefix === 'other') {
      return prefixOther;
    } else {
      return mapping[prefix];
    }
  };

  const getImage = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: false })
      .then((dataUrl) => {
        setImage(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const priceToggle = () => {
    console.log(priceShow);
    setPriceShow(!priceShow);
  };
  return (
    <>
      <div className="mx-auto max-w-[980px] space-y-4 px-4">
        <div className="navbar rounded-box bg-base-100 shadow-xl print:hidden">
          <div className="navbar-start"></div>
          <div className="navbar-end space-x-2">
            {/* <div className="rounded-box mb-8 flex justify-end space-x-2 p-4 shadow-lg"> */}
            <button className="btn" onClick={priceToggle}>
              Hide/Show Price
            </button>
            <button className="btn" onClick={getImage}>
              Capture
            </button>
            <button
              className="btn"
              onClick={() => navigate({ to: `/quotations/${quotationId}/preview-mfo` })}
            >
              Preview MFO
            </button>
            <button className="btn" onClick={() => setIsQuotation(!isQuotation)}>
              {isQuotation ? 'Invoice' : 'Quotation'}
            </button>

            <button className="btn gap-2" onClick={handlePrint}>
              พิมพ์
              <PrinterIcon className="h-4 w-4" />
            </button>
            <div>
              <CreateMFOToggle
                onSubmitClick={async (formData) => {
                  const { data } = await addMFO({
                    formData,
                    quotationId,
                  });
                  navigate({ to: `/quotations/mfo/${data.running_number}/print` });
                }}
              />
            </div>
          </div>
        </div>
        {image ? (
          <div className="block border-2 border-solid border-amber-700">
            {/* <a
              href={image || ''}
              // onClick={(e) => e.preventDefault()}
              download={quotation?.running_number}
            >
            </a> */}
            <img src={image || ''} alt={quotation?.running_number} />
          </div>
        ) : null}

        {quotation && quotationPrint && (
          <div>
            <div ref={ref} className="bg-white p-4">
              <style>
                {`
                @media print {
                  html {
                    font-size: 12px;
                    padding: 3rem;
                  }
                }
              `}
              </style>
              <div className="page-container space-y-4 bg-white font-sarabun">
                <div className="flex space-x-6">
                  <div className="flex-shrink-0">
                    <img src={logo} alt="RK Steel" className="w-full max-w-[145px]" />
                  </div>
                  <div className="flex flex-1 flex-col">
                    <h1 className="text-2xl font-bold text-[#1F56B1]">
                      บริษัท เหล็กร่มเกล้า จำกัด
                    </h1>
                    <span>เลขที่ 93 หมู่ที่ 6 ตำบลคลองหลวงแพ่ง อำเภอเมืองฉะเชิงเทรา</span>
                    <span>จังหวัดฉะเชิงเทรา 24000 Tax ID: {formatTaxId('0105559029172')}</span>
                    <div className="flex space-x-2">
                      <span>ผู้เสนอราคา: คุณ{quotation.staff.name}</span>
                      <span>โทร. {quotation.staff.phone}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span>อีเมล {quotation.staff.email}</span>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="flex items-center space-x-2 text-right">
                      <div className="flex-1">
                        <span className="text-2xl">ใบเสนอราคา</span>
                      </div>
                      <div className="w-[5rem] text-[#3989c6]">
                        <span>{quotation.running_number}</span>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 text-right">
                      <div className="flex-1">
                        <span>วันที่</span>
                      </div>
                      <div className="w-[5rem]">
                        <span>{format(new Date(quotation.create_date), 'dd/MM/yyyy')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider" />
                <h2 className="text-center text-2xl font-bold">
                  {isQuotation ? 'ใบเสนอราคา' : 'ใบแจ้งหนี้'}
                </h2>
                <div className="grid grid-cols-2 border border-black p-4">
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col">
                      <span>เรียน / Attention:</span>
                      <span className="text-2xl font-bold">
                        {customerPrefix(quotation.prefix, quotation.prefix_other)} {quotation.name}
                      </span>
                    </div>
                    <div className="flex space-x-2">
                      <span>โทร / Tel:</span>
                      <span className="flex-1">{quotation.phone}</span>
                    </div>
                  </div>
                  <div>
                    {quotation.note ? (
                      <>
                        <div className="flex space-x-2">
                          <span>หมายเหตุ:</span>
                        </div>
                        <div>
                          <span className="flex-1 text-2xl">{quotation.note}</span>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="hidden flex-col">
                    <div className="flex space-x-2">
                      <span className="">เลขประจำตัวผู้เสียภาษี</span>
                      <span className="flex-1">{formatTaxId(quotation.tax_id) || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">บริษัท</span>
                      <span className="flex-1">{quotation.business_name || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">สาขา</span>
                      <span className="flex-1">{quotation.branch || '-'}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span className="">ที่อยู่</span>
                      <span className="flex-1">{quotation.address || '-'}</span>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="quotation-table">
                  <table className="w-full bg-[#eeeeee]">
                    <thead className="text-center text-[0.85rem]">
                      <tr>
                        <th className="w-[2.25rem]">
                          <span className="block">
                            ลำดับ <br />
                            ITEM
                          </span>
                        </th>
                        <th className="">
                          <span className="block">
                            รายการ <br />
                            DESCRIPTION
                          </span>
                        </th>
                        <th className="w-[7.5%]">
                          <span className="block">
                            จำนวน <br />
                            QUANTITY
                          </span>
                        </th>
                        <th className="w-[10%]">
                          <span className="block">
                            นน. ต่อชิ้น <br />
                            โดยประมาณ
                          </span>
                        </th>
                        <th className="w-[6rem]">
                          <span className="block">
                            น้ำหนักรวม <br />
                            WEIGHT (kg)
                          </span>
                        </th>
                        <th className="w-[7%]">
                          <span className="block">
                            ราคา <br />
                            PRICE
                          </span>
                        </th>
                        <th className="w-[10%]">
                          <span className="block">
                            จำนวนเงิน <br />
                            AMOUNT
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-base">
                      {quotationPrint.items.map((item, index) => {
                        if (item.type === 'weighted_product') {
                          return (
                            <QuotationProductWeightedRow
                              priceShow={priceShow}
                              key={`${item.type}_${index}`}
                              item={item as IQuotationPrintWeightedItem}
                              index={index}
                            />
                          );
                        }
                        if (item.type === 'normal_product') {
                          return (
                            <QuotationProductNormalRow
                              priceShow={priceShow}
                              key={`${item.type}_${index}`}
                              item={item as unknown as IQuotationPrintNormalItem}
                              index={index}
                            />
                          );
                        }
                        return (
                          <QuotationProductOtherRow
                            priceShow={priceShow}
                            key={`${item.type}_${index}`}
                            item={item as unknown as IQuotationPrintOtherItem}
                            index={index}
                          />
                        );
                      })}
                      <tr className="quotation-table-footer">
                        <td colSpan={2}></td>
                        <td colSpan={2} className="!border-gray-300">
                          น้ำหนักรวม
                        </td>
                        <td className="!border-gray-300 font-bold">
                          {numberFormatter(2).format(quotationPrint.net_weight)}
                        </td>
                        <td className="!border-gray-300">รวมเงิน</td>
                        <td className="!border-gray-300">
                          {priceShow
                            ? numberFormatter(2).format(quotationPrint.quotation_total)
                            : ''}
                        </td>
                      </tr>
                      <tr className="quotation-table-footer">
                        <td colSpan={2}></td>
                        <td colSpan={3} className="!border-[#1e56b1]"></td>
                        <td className="!border-[#1e56b1]">VAT.</td>
                        <td className="!border-[#1e56b1]">
                          {priceShow ? numberFormatter(2).format(quotationPrint.vat) : ''}
                        </td>
                      </tr>
                      <tr className="quotation-table-footer">
                        <td colSpan={2}></td>
                        <td colSpan={2}></td>
                        <td colSpan={2} className="text-2xl text-[#3989c6]">
                          รวมทั้งสิ้น
                        </td>
                        <td className="text-2xl text-[#3989c6]">
                          {priceShow
                            ? numberFormatter(2).format(quotationPrint.quotation_net_total)
                            : ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="ml-auto w-full max-w-md space-y-4 border-x-4 border-[#3989c6] px-6 py-1 text-xl">
                  <p>
                    <span className="font-bold">เงื่อนไข:</span> ชำระเต็มจำนวนก่อนผลิตสินค้า
                  </p>
                  <p>กรุณาโอนมาที่บัญชีเลขที่: บจก.เหล็กร่มเกล้า</p>
                  <p className="text-2xl">กสิกรไทย 010-1-58833-5</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuotationPrint;

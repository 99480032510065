import {
  IQuotationMFOPrintWeightedItem,
  IQuotationPrintWeightedItem,
} from '../../../interfaces/quotation';
import { getImageUrl } from '../../../utils';
import { numberFormatter } from '../../../utils/formatter';

interface IQuotationProductWeightedRowProps {
  priceShow: boolean;
  item: IQuotationPrintWeightedItem | IQuotationMFOPrintWeightedItem;
  index: number;
  isMFO?: boolean;
  isDelivery?: boolean;
}

const QuotationProductWeightedRow = (props: IQuotationProductWeightedRowProps) => {
  const { priceShow, item, index, isMFO = false, isDelivery = false } = props;
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <div className="flex items-center justify-between">
          <div className="flex-1 text-lg text-[#3989c6]">
            <span>
              {item.desc}
              {item.is_assembling ? (
                <span className="badge badge-warning badge-lg font-bold">ผูก</span>
              ) : (
                ''
              )}
            </span>
          </div>
          <div className="ml-auto w-24 flex-shrink-0">
            <img src={getImageUrl(item.image)} alt={item.desc} className="w-24" />
          </div>
        </div>
      </td>
      <td className="text-right">
        {item.qty} {item.unit}
      </td>
      <td className="text-right">{numberFormatter(3).format(item.weight)}</td>
      <td className="text-right">
        <span className="font-bold">{numberFormatter(2).format(item.weight_total)}</span>
      </td>
      {isMFO && 'color' in item && (
        <td className="relative">
          <ul className="absolute top-0 bottom-0 left-0 right-0 flex">
            {item.color.map((color) => (
              <li
                key={color}
                className="h-full w-full"
                style={{
                  backgroundColor: `#${color}`,
                }}
              ></li>
            ))}
          </ul>
        </td>
      )}
      {!isDelivery && !isMFO && (
        <>
          <td className="text-right">
            {priceShow ? numberFormatter(2).format(item.product_type_price) : ''}
            <br />
            {item.price_unit}
          </td>
          <td className="text-right">{priceShow ? numberFormatter(2).format(item.total) : ''}</td>
        </>
      )}
    </tr>
  );
};

export default QuotationProductWeightedRow;

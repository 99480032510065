import {
  EyeIcon,
  IdentificationIcon,
  MapIcon,
  PhoneIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Link, useLoadRoute, useNavigate } from '@tanstack/react-location';
import { useQueryClient } from '@tanstack/react-query';

import { postCreateQuotation } from '../../hooks/quotation';
import { fetchStaffs } from '../../hooks/staff';
import { ICustomerDataResponse } from '../../interfaces/customer';
import { formatTaxId } from '../../utils/formatter';
import { prefixMapper } from '../../utils/prefixMapper';
import CreateQuotationToggle from '../Popup/CreateQuotation/CreateQuotationToggle';

interface ICustomerCardProps {
  data: ICustomerDataResponse;
}

const CustomerCard = (props: ICustomerCardProps) => {
  const { data } = props;

  const loadRoute = useLoadRoute();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onCreateQuotation = async (staffId: string) => {
    const quotation = await postCreateQuotation({
      customer_id: data.id,
      staff_id: Number(staffId),
    });
    navigate({ to: `/quotations/${quotation.running_number}` });
  };
  return (
    <div className="rounded-box flex flex-col justify-between space-y-4 p-4 shadow-lg duration-300 ease-in-out hover:shadow-2xl">
      <div>
        <div className="flex gap-2">
          <UserIcon className="mt-1 h-4 w-4 flex-shrink-0 text-gray-400" />
          <span className="font-bold">
            {data.prefix !== 'other' ? prefixMapper(data.prefix) : data.prefix_other} {data.name}
          </span>
        </div>
        <div className="flex gap-2">
          <PhoneIcon className="mt-1 h-4 w-4 flex-shrink-0 text-gray-400" />
          <a href={`tel:${data.phone}`}>{data.phone}</a>
        </div>
        <div className="flex gap-2">
          <MapIcon className="mt-1 h-4 w-4 flex-shrink-0 text-gray-400" />
          <span>{data.address}</span>
        </div>
        <div className="flex gap-2">
          <IdentificationIcon className="mt-1 h-4 w-4 flex-shrink-0 text-gray-400" />
          <span>{formatTaxId(data.tax_id)}</span>
        </div>
      </div>
      <div className="flex space-x-2">
        <div className="w-1/2">
          <CreateQuotationToggle
            label="สร้าง"
            onSubmitClick={onCreateQuotation}
            onMouseEnter={() =>
              queryClient.getQueryData(['staffs']) ??
              queryClient.fetchQuery(['staffs'], () => fetchStaffs())
            }
          />
        </div>
        <div className="w-1/2">
          <Link
            to={`/customers/${data.id}`}
            onMouseEnter={() => loadRoute({ to: data.id })}
            className="btn btn-block gap-2"
          >
            ดูข้อมูล
            <EyeIcon className="hidden h-4 w-4 sm:block" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CustomerCard;

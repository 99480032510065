import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link, useLoadRoute } from '@tanstack/react-location';

interface IBackButtonProps {
  to?: string;
}

const BackButton = ({ to = '..' }: IBackButtonProps) => {
  const loadRoute = useLoadRoute();
  return (
    <Link
      to={to}
      onMouseEnter={() => loadRoute({ to })}
      className="btn btn-ghost gap-2 bg-white shadow-lg"
    >
      <ArrowLeftIcon className="h-5 w-5" />
      Back
    </Link>
  );
};

export default BackButton;

import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';

import { updateEtax } from '../../hooks/invoice';
import { IInvoice, IInvoiceItem } from '../../interfaces/invoice';

interface Props {
  invoiceInfo: IInvoice;
  items: IInvoiceItem[];
  email: string;
}

const EtaxUploadButton = (props: Props) => {
  const { invoiceInfo, items, email } = props;

  const mapInvoiceData = (invoice: any, items: any, email: string) => {
    const addressSplit = invoice.address.split(' ');
    const postccode = addressSplit.pop();
    const mappedData = {
      PDFContent: '',
      SellerBranchId: '00000',
      SellerTaxId: '0105559029172',
      ServiceCode: 'S06',
      TextContent: {
        'B01-BUYER_ID': '',
        'B02-BUYER_NAME': invoice.name,
        'B03-BUYER_TAX_ID_TYPE': invoice.taxId ? 'TXID' : 'OTHR',
        'B04-BUYER_TAX_ID': invoice.taxId ? invoice.taxId : 'N/A',
        'B05-BUYER_BRANCH_ID': invoice.taxId ? '00000' : '',
        'B06-BUYER_CONTACT_PERSON_NAME': '',
        'B07-BUYER_CONTACT_DEPARTMENT_NAME': '',
        'B08-BUYER_URIID': email ? email : '',
        'B09-BUYER_CONTACT_PHONE_NO': '',
        'B10-BUYER_POST_CODE': invoice.address ? postccode : '00000',
        'B11-BUYER_BUILDING_NAME': '',
        'B12-BUYER_BUILDING_NO': '',
        'B13-BUYER_ADDRESS_LINE1': invoice.address ? addressSplit.join(' ') : '',
        'B14-BUYER_ADDRESS_LINE2': '',
        'B15-BUYER_ADDRESS_LINE3': '',
        'B16-BUYER_ADDRESS_LINE4': '',
        'B17-BUYER_ADDRESS_LINE5': '',
        'B18-BUYER_STREET_NAME': '',
        'B19-BUYER_CITY_SUB_DIV_ID': '',
        'B20-BUYER_CITY_SUB_DIV_NAME': '',
        'B21-BUYER_CITY_ID': '',
        'B22-BUYER_CITY_NAME': '',
        'B23-BUYER_COUNTRY_SUB_DIV_ID': '',
        'B24-BUYER_COUNTRY_SUB_DIV_NAME': '',
        'B25-BUYER_COUNTRY_ID': 'TH',
        'C01-SELLER_TAX_ID': '0105559029172',
        'C02-SELLER_BRANCH_ID': '00000',
        'C03-FILE_NAME': '',
        'F01-LINE_TOTAL_COUNT': items.length.toString(),
        'F02-DELIVERY_OCCUR_DTM': '',
        'F03-INVOICE_CURRENCY_CODE': 'THB',
        'F04-TAX_TYPE_CODE1': 'VAT',
        'F05-TAX_CAL_RATE1': '7.00',
        'F06-BASIS_AMOUNT1': invoice.invoiceAmount.toFixed(2),
        'F07-BASIS_CURRENCY_CODE1': 'THB',
        'F08-TAX_CAL_AMOUNT1': invoice.vat.toFixed(2),
        'F09-TAX_CAL_CURRENCY_CODE1': 'THB',
        'F10-TAX_TYPE_CODE2': '',
        'F11-TAX_CAL_RATE2': '',
        'F12-BASIS_AMOUNT2': '',
        'F13-BASIS_CURRENCY_CODE2': '',
        'F14-TAX_CAL_AMOUNT2': '',
        'F15-TAX_CAL_CURRENCY_CODE2': '',
        'F16-TAX_TYPE_CODE3': '',
        'F17-TAX_CAL_RATE3': '',
        'F18-BASIS_AMOUNT3': '',
        'F19-BASIS_CURRENCY_CODE3': '',
        'F20-TAX_CAL_AMOUNT3': '',
        'F21-TAX_CAL_CURRENCY_CODE3': '',
        'F22-TAX_TYPE_CODE4': '',
        'F23-TAX_CAL_RATE4': '',
        'F24-BASIS_AMOUNT4': '',
        'F25-BASIS_CURRENCY_CODE4': '',
        'F26-TAX_CAL_AMOUNT4': '',
        'F27-TAX_CAL_CURRENCY_CODE4': '',
        'F28-ALLOWANCE_CHARGE_IND': '',
        'F29-ALLOWANCE_ACTUAL_AMOUNT': '',
        'F30-ALLOWANCE_ACTUAL_CURRENCY_CODE': '',
        'F31-ALLOWANCE_REASON_CODE': '',
        'F32-ALLOWANCE_REASON': '',
        'F33-PAYMENT_TYPE_CODE': '',
        'F34-PAYMENT_DESCRIPTION': '',
        'F35-PAYMENT_DUE_DTM': '',
        'F36-ORIGINAL_TOTAL_AMOUNT': invoice.total.toFixed(2),
        'F37-ORIGINAL_TOTAL_CURRENCY_CODE': 'THB',
        'F38-LINE_TOTAL_AMOUNT': invoice.invoiceAmount.toFixed(2),
        'F39-LINE_TOTAL_CURRENCY_CODE': 'THB',
        'F40-ADJUSTED_INFORMATION_AMOUNT': '',
        'F41-ADJUSTED_INFORMATION_CURRENCY_CODE': '',
        'F42-ALLOWANCE_TOTAL_AMOUNT': '',
        'F43-ALLOWANCE_TOTAL_CURRENCY_CODE': '',
        'F44-CHARGE_TOTAL_AMOUNT': '',
        'F45-CHARGE_TOTAL_CURRENCY_CODE': '',
        'F46-TAX_BASIS_TOTAL_AMOUNT': invoice.invoiceAmount.toFixed(2),
        'F47-TAX_BASIS_TOTAL_CURRENCY_CODE': 'THB',
        'F48-TAX_TOTAL_AMOUNT': invoice.vat.toFixed(2),
        'F49-TAX_TOTAL_CURRENCY_CODE': 'THB',
        'F50-GRAND_TOTAL_AMOUNT': invoice.bankAmount.toFixed(2),
        'F51-GRAND_TOTAL_CURRENCY_CODE': 'THB',
        'F52-TERM_PAYMENT': '',
        'F53-WITHHOLDINGTAX_TYPE1': '',
        'F54-WITHHOLDINGTAX_DESCRIPTION1': '',
        'F55-WITHHOLDINGTAX_RATE1': '',
        'F56-WITHHOLDINGTAX_BASIS_AMOUNT1': '',
        'F57-WITHHOLDINGTAX_TAX_AMOUNT1': '',
        'F58-WITHHOLDINGTAX_TYPE2': '',
        'F59-WITHHOLDINGTAX_DESCRIPTION2': '',
        'F60-WITHHOLDINGTAX_RATE2': '',
        'F61-WITHHOLDINGTAX_BASIS_AMOUNT2': '',
        'F62-WITHHOLDINGTAX_TAX_AMOUNT2': '',
        'F63-WITHHOLDINGTAX_TYPE3': '',
        'F64-WITHHOLDINGTAX_DESCRIPTION3': '',
        'F65-WITHHOLDINGTAX_RATE3': '',
        'F66-WITHHOLDINGTAX_BASIS_AMOUNT3': '',
        'F67-WITHHOLDINGTAX_TAX_AMOUNT3': '',
        'F68-WITHHOLDINGTAX_TOTAL_AMOUNT': '',
        'F69-ACTUAL_PAYMENT_TOTAL_AMOUNT': '',
        'F70-DOCUMENT_REMARK1': '',
        'F71-DOCUMENT_REMARK2': '',
        'F72-DOCUMENT_REMARK3': '',
        'F73-DOCUMENT_REMARK4': '',
        'F74-DOCUMENT_REMARK5': '',
        'F75-DOCUMENT_REMARK6': '',
        'F76-DOCUMENT_REMARK7': '',
        'F77-DOCUMENT_REMARK8': '',
        'F78-DOCUMENT_REMARK9': '',
        'F79-DOCUMENT_REMARK10': '',
        'F80-DOCUMENT_REMARK11': '',
        'H01-DOCUMENT_TYPE_CODE': 'T03',
        'H02-DOCUMENT_NAME': 'ใบเสร็จรับเงิน/ใบกำกับภาษี',
        'H03-DOCUMENT_ID': invoice.invoiceNo,
        'H04-DOCUMENT_ISSUE_DTM': new Date(invoice.createdAt).toISOString(),
        'H05-CREATE_PURPOSE_CODE': '',
        'H06-CREATE_PURPOSE': '',
        'H07-ADDITIONAL_REF_ASSIGN_ID': '',
        'H08-ADDITIONAL_REF_ISSUE_DTM': '',
        'H09-ADDITIONAL_REF_TYPE_CODE': '',
        'H10-ADDITIONAL_REF_DOCUMENT_NAME': '',
        'H11-DELIVERY_TYPE_CODE': '',
        'H12-BUYER_ORDER_ASSIGN_ID': '',
        'H13-BUYER_ORDER_ISSUE_DTM': '',
        'H14-BUYER_ORDER_REF_TYPE_CODE': '',
        'H15-DOCUMENT_REMARK': '',
        'H16-VOUCHER_NO': '',
        'H17-SELLER_CONTACT_PERSON_NAME': '',
        'H18-SELLER_CONTACT_DEPARTMENT_NAME': '',
        'H19-SELLER_CONTACT_URIID': '',
        'H20-SELLER_CONTACT_PHONE_NO': '',
        'H21-FLEX_FIELD': '',
        'H22-SELLER_BRANCH_ID': '00000',
        'H23-SOURCE_SYSTEM': '',
        'H24-ENCRYPT_PASSWORD': '',
        'H25-PDF_TEMPLATE_ID': '',
        'H26-SEND_MAIL_IND': email ? 'Y' : 'N',
        'H27-PDF_NAME': '',
        LINE_ITEM_INFORMATION: items.map((item: any, index: number) => ({
          'L01-LINE_ID': (index + 1).toString(),
          'L02-PRODUCT_ID': '',
          'L03-PRODUCT_NAME': item.name.trim(),
          'L04-PRODUCT_DESC': item.specs || '',
          'L05-PRODUCT_BATCH_ID': '',
          'L06-PRODUCT_EXPIRE_DTM': '',
          'L07-PRODUCT_CLASS_CODE': '',
          'L08-PRODUCT_CLASS_NAME': '',
          'L09-PRODUCT_ORIGIN_COUNTRY_ID': '',
          'L10-PRODUCT_CHARGE_AMOUNT': item.name.includes('ส่วนลด')
            ? '0.00'
            : item.pricePerUnit.toFixed(2),
          'L11-PRODUCT_CHARGE_CURRENCY_CODE': 'THB',
          'L12-PRODUCT_ALLOWANCE_CHARGE_IND': '',
          'L13-PRODUCT_ALLOWANCE_ACTUAL_AMOUNT': '',
          'L14-PRODUCT_ALLOWANCE_ACTUAL_CURRENCY_CODE': '',
          'L15-PRODUCT_ALLOWANCE_REASON_CODE': '',
          'L16-PRODUCT_ALLOWANCE_REASON': '',
          'L17-PRODUCT_QUANTITY': item.amount.toFixed(2),
          'L18-PRODUCT_UNIT_CODE': item.unit || '',
          'L19-PRODUCT_QUANTITY_PER_UNIT': '',
          'L20-LINE_TAX_TYPE_CODE': 'VAT',
          'L21-LINE_TAX_CAL_RATE': '7.00',
          'L22-LINE_BASIS_AMOUNT': item.name.includes('ส่วนลด') ? '0.00' : item.price.toFixed(2),
          'L23-LINE_BASIS_CURRENCY_CODE': 'THB',
          'L24-LINE_TAX_CAL_AMOUNT': item.name.includes('ส่วนลด')
            ? '0.00'
            : (item.price * 0.07).toFixed(2),
          'L25-LINE_TAX_CAL_CURRENCY_CODE': 'THB',
          'L26-LINE_ALLOWANCE_CHARGE_IND': item.name.includes('ส่วนลด') ? 'false' : '',
          'L27-LINE_ALLOWANCE_ACTUAL_AMOUNT': item.name.includes('ส่วนลด')
            ? Math.abs(item.price).toFixed(2)
            : '',
          'L28-LINE_ALLOWANCE_ACTUAL_CURRENCY_CODE': item.name.includes('ส่วนลด') ? 'THB' : '',
          'L29-LINE_ALLOWANCE_REASON_CODE': '',
          'L30-LINE_ALLOWANCE_REASON': '',
          'L31-LINE_TAX_TOTAL_AMOUNT': '',
          'L32-LINE_TAX_TOTAL_CURRENCY_CODE': '',
          'L33-LINE_NET_TOTAL_AMOUNT': item.name.includes('ส่วนลด')
            ? '0.00'
            : item.price.toFixed(2),
          'L34-LINE_NET_TOTAL_CURRENCY_CODE': 'THB',
          'L35-LINE_NET_INCLUDE_TAX_TOTAL_AMOUNT': item.name.includes('ส่วนลด')
            ? '0.00'
            : (item.price * 1.07).toFixed(2),
          'L36-LINE_NET_INCLUDE_TAX_TOTAL_CURRENCY_CODE': 'THB',
          'L37-PRODUCT_REMARK1': '',
          'L38-PRODUCT_REMARK2': '',
          'L39-PRODUCT_REMARK3': '',
          'L40-PRODUCT_REMARK4': '',
          'L41-PRODUCT_REMARK5': '',
          'L42-PRODUCT_REMARK6': '',
          'L43-PRODUCT_REMARK7': '',
          'L44-PRODUCT_REMARK8': '',
          'L45-PRODUCT_REMARK9': '',
          'L46-PRODUCT_REMARK10': '',
        })),
        'T01-TOTAL_DOCUMENT_COUNT': '1',
      },
    };

    return mappedData;
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const jsonData = mapInvoiceData(invoiceInfo, items, email) || {};
        const result = reader?.result;

        if (typeof result === 'string') {
          const data = result.split(',').pop() || '';
          jsonData.PDFContent = data;
        } else {
          console.error('Error: reader.result is not a string or is undefined');
          return; // Exit if there's an error
        }

        console.log(jsonData);

        // API call
        try {
          // const etaxInfo = {
          //   url: 'https://uatservice-etax.one.th/service/etaxsigndocumentjson',
          //   token:
          //     'Mjc6R29DY1JvRlA5RGc5QUhlNWh6aU15MUxvRHFwM1RJNXpTVTVrVUVTRTpleUowWVhocFpDSTZJakF4TURVMU5Ua3dNamt4TnpJaUxDSndZWE56ZDI5eVpDSTZJbE4wWldWc1FIQmhjM013TVNKOUExNkZVVkdLOWJxWWl1RUVOVG12OVd0ZUZHOVRtbG1Y',
          // };
          const etaxInfo = {
            url: 'https://service-etax.one.th/service/etaxsigndocumentjson',
            token:
              'MjY6cno2a2lKeGE2Q3JXOFNUcEYzcHlmZW0wN29LRDl3cUxKUTFjV3ZZOTpleUowWVhocFpDSTZJ akF4TURVMU5Ua3dNamt4TnpJaUxDSndZWE56ZDI5eVpDSTZJazF2TkRreE16RTRNU0VpZlEyYVhiW DZwVVFYZ1R1MkhIMmFpdEJzdVNNWGFQcDZ4ag==',
          };
          const response = await fetch(etaxInfo.url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: etaxInfo.token,
            },
            body: JSON.stringify(jsonData),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const responseData = await response.json();
          await updateEtax(invoiceInfo.invoiceNo, responseData.pdfURL, responseData.xmlURL);
          console.log('API Response:', responseData);
        } catch (error) {
          console.error('Error calling API:', error);
        }
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsDataURL(file); // Convert file to Base64
    }
  };

  const handleBrowseFile = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.click(); // Programmatically click the file input
    }
  };

  return (
    <div>
      <button className="btn gap-2" onClick={handleBrowseFile}>
        <span className="hidden sm:inline">Etax Upload</span>
        <ArrowUpTrayIcon className="h-5 w-5" />
      </button>
      <input id="fileInput" type="file" className="hidden" onChange={handleFileChange} />
    </div>
  );
};

export default EtaxUploadButton;

import { IQuotationPrintNormalItem } from '../../../interfaces/quotation';
import { getImageUrl } from '../../../utils';
import { numberFormatter } from '../../../utils/formatter';

interface IQuotationProductNormalRowProps {
  priceShow: boolean;
  item: IQuotationPrintNormalItem;
  index: number;
  isMFO?: boolean;
  isDelivery?: boolean;
}

const QuotationProductNormalRow = (props: IQuotationProductNormalRowProps) => {
  const { priceShow, item, index, isMFO = false, isDelivery = false } = props;
  return (
    <tr>
      <td>{index + 1}</td>
      <td>
        <div className="flex items-center justify-between text-lg text-[#3989c6]">
          <div className="flex-1">
            <span>{item.desc}</span>
          </div>
          <div className="w-24 flex-shrink-0">
            <img src={getImageUrl(item.image)} alt={item.name} className="w-24" />
          </div>
        </div>
      </td>
      <td className="text-right">
        {item.qty} {item.unit}
      </td>
      <td></td>
      <td></td>
      {!isDelivery && !isMFO && (
        <>
          <td className="text-right">
            {priceShow ? `${numberFormatter(2).format(+item.price)} ${item.price_unit}` : ''}
          </td>
          <td className="text-right">
            {priceShow ? numberFormatter(2).format(item.total_price) : ''}
          </td>
          {isMFO && <td></td>}
        </>
      )}
    </tr>
  );
};

export default QuotationProductNormalRow;

import { useMatch } from '@tanstack/react-location';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

import BackButton from '../../components/BackButton';
import CustomerForm from '../../components/Customer/CustomerForm';
import CustomerQuotations from '../../components/Customer/CustomerQuotations';
import { fetchCustomerQuotationsById, useCustomer } from '../../hooks/customer';

type ActiveTab = 'CUSTOMER_FORM' | 'CUSTOMER_QUOTATIONS';
const cookieKey = 'customer-quotations-active-tab';

const Customer = () => {
  const {
    params: { customerId },
  } = useMatch();
  const queryClient = useQueryClient();

  const [cookies, setCookie] = useCookies([cookieKey]);
  const [activeTab, setActiveTab] = useState<ActiveTab>(cookies[cookieKey]);

  const onSwitchTab = (tab: ActiveTab) => {
    setActiveTab(tab);
    setCookie(cookieKey, tab, {
      path: '/',
    });
  };
  const { data, isLoading } = useCustomer(customerId);

  return (
    <div className="space-y-4">
      <BackButton />
      <div className="rounded-box space-y-4 bg-base-100 px-8 py-4 shadow-lg">
        <div className="flex items-center justify-center">
          <div className="tabs tabs-boxed">
            <button
              className={`tab tab-lg ${activeTab === 'CUSTOMER_QUOTATIONS' ? '' : 'tab-active'}`}
              onClick={() => onSwitchTab('CUSTOMER_FORM')}
            >
              รายละเอียดลูกค้า
            </button>
            <button
              className={`tab tab-lg ${activeTab === 'CUSTOMER_QUOTATIONS' ? 'tab-active' : ''}`}
              onClick={() => onSwitchTab('CUSTOMER_QUOTATIONS')}
              onMouseEnter={() =>
                queryClient.getQueryData(['customers', customerId, 'quotations']) ??
                queryClient.fetchQuery(['customers', customerId, 'quotations'], () =>
                  fetchCustomerQuotationsById(customerId)
                )
              }
            >
              ประวัติใบเสนอราคา
            </button>
          </div>
        </div>
        {isLoading ? (
          'Loading...'
        ) : activeTab === 'CUSTOMER_QUOTATIONS' ? (
          <CustomerQuotations />
        ) : (
          <CustomerForm customerData={data} isView />
        )}
      </div>
    </div>
  );
};

export default Customer;

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import axios from 'axios';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import App from './App';
import { BASE_API_URL } from './constants';

axios.defaults.baseURL = BASE_API_URL;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
      <ToastContainer draggable toastClassName="font-sans rounded-lg" />
    </CookiesProvider>
  </React.StrictMode>
);

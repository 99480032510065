import { useNavigate } from '@tanstack/react-location';
import { format } from 'date-fns';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';

import { createInvoice } from '../../../hooks/invoice';

interface INewInvoicePopup {
  togglePopup: () => void;
  popupId: string;
}

const NewInvoicePopup = (props: INewInvoicePopup) => {
  const navigate = useNavigate();
  const { togglePopup, popupId } = props;

  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [price, setPrice] = useState<number>();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const onSubmit = async () => {
    setIsCreating(true);
    const data = await createInvoice(date, price || 0);
    const invoiceNo = data?.data?.invoiceNo || '';
    navigate({ to: `/invoices/${invoiceNo}` });
  };

  const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  const onPriceChange = (values: NumberFormatValues) => {
    setPrice(values.floatValue);
  };
  return (
    <>
      <input type="checkbox" id={popupId} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="text-lg font-bold">เลข Invoice: CA2202086</h3>
          <div className="w-full">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">วันที่</span>
              </label>
              <input
                type="date"
                className="input input-bordered w-full"
                value={date}
                onChange={onDateChange}
              />
            </div>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">ราคา (บาท)</span>
              </label>
              <NumericFormat
                value={price}
                onValueChange={onPriceChange}
                displayType="input"
                type="text"
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                className="input input-bordered w-full"
              />
            </div>
          </div>
          <div className="modal-action">
            <label className="btn btn-ghost" onClick={togglePopup}>
              Cancel
            </label>
            <button className="btn" disabled={isCreating} onClick={onSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewInvoicePopup;

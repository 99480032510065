import {
  ArrowDownOnSquareIcon,
  AtSymbolIcon,
  BuildingOfficeIcon,
  MapIcon,
  PencilSquareIcon,
  PhoneIcon,
  UserPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { ChangeEvent, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { NumberFormatValues } from 'react-number-format/types/types';
import { omit } from 'underscore';

import { fetchCustomerByPhone, postCreateCustomer, putEditCustomer } from '../../hooks/customer';
import { postCreateQuotation } from '../../hooks/quotation';
import { CustomerDataKey, ICustomerData } from '../../interfaces/customer';
import CreateQuotationToggle from '../Popup/CreateQuotation/CreateQuotationToggle';

interface ICustomerFormProps {
  customerData?: ICustomerData;
  isNewCustomer?: boolean;
  isView?: boolean;
}

const initCustomerData: ICustomerData = {
  prefix: 'mr',
  prefix_other: '',
  name: '',
  phone: '',
  email: '',
  line: '',
  business_name: '',
  branch: '',
  tax_id: '',
  address: '',
};

const CustomerForm = (props: ICustomerFormProps) => {
  const { customerData = initCustomerData, isNewCustomer = false, isView = false } = props;

  const {
    params: { customerId },
  } = useMatch();

  const [initialFormData, setInitialFormData] = useState<ICustomerData>(customerData);
  const [formData, setFormData] = useState<ICustomerData>(customerData);
  const [isEditing, setIsEditing] = useState(!isView);
  const navigate = useNavigate();

  const onEditInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    key: CustomerDataKey
  ) => {
    setFormData((prev) => {
      const newItem: ICustomerData = {
        ...prev,
        [key]: event.target.value,
      };
      return newItem;
    });
  };

  const checkPhone = async (phone: string) => {
    const data = await fetchCustomerByPhone(phone);
    if (data.length !== 0) {
      navigate({ to: `/customers/${data[0].id}` });
    }
  };

  const onEditValueChange = async (values: NumberFormatValues, key: CustomerDataKey) => {
    const { value } = values;
    if (key === 'phone') {
      await checkPhone(value);
    }
    setFormData((prev) => {
      const newItem: ICustomerData = {
        ...prev,
        [key]: value,
      };
      return newItem;
    });
  };

  const onCreateCustomer = async () => {
    const resp = await postCreateCustomer(formData);
    navigate({ to: `/customers/${resp.id}` });
  };

  const onEditCustomer = async () => {
    setIsEditing(false);
    const editedFormData = omit(formData, 'id') as ICustomerData;
    const resp = await putEditCustomer(editedFormData, customerId);
    setInitialFormData(resp);
    setFormData(resp);
  };

  const onCreateQuotationNewCustomer = async (staffId: string) => {
    const customer = await postCreateCustomer(formData);
    const quotation = await postCreateQuotation({
      customer_id: customer.id,
      staff_id: Number(staffId),
    });
    navigate({ to: `/quotations/${quotation.running_number}` });
  };

  const onCreateQuotationExistingCustomer = async (staffId: string) => {
    const quotation = await postCreateQuotation({
      customer_id: Number(customerId),
      staff_id: Number(staffId),
    });
    navigate({ to: `/quotations/${quotation.running_number}` });
  };

  return (
    <div className="space-y-4">
      <div>
        <div className="flex items-end space-x-2">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">คำนำหน้า</span>
            </label>
            <select
              className="select select-bordered"
              value={formData.prefix}
              onChange={(e) => onEditInputChange(e, 'prefix')}
              disabled={!isEditing}
            >
              <option value="mr">คุณ</option>
              <option value="org">บริษัท</option>
              <option value="engineer">ช่าง</option>
              <option value="other">อื่นๆ</option>
            </select>
          </div>
          {formData.prefix === 'other' && (
            <div className="form-control w-full">
              <input
                type="text"
                placeholder="อื่น ๆ เช่น บลจ."
                className="input input-bordered w-full"
                value={formData.prefix_other}
                onChange={(e) => onEditInputChange(e, 'prefix_other')}
                disabled={!isEditing}
              />
            </div>
          )}
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">
              ชื่อ <span className="text-red-500">*</span>
            </span>
          </label>
          <input
            type="text"
            placeholder="สมชาย"
            className="input input-bordered w-full"
            value={formData.name}
            onChange={(e) => onEditInputChange(e, 'name')}
            disabled={!isEditing}
          />
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">
              เบอร์โทรศัพท์ <span className="text-red-500">*</span>
            </span>
          </label>
          <div className="relative">
            <PatternFormat
              displayType="input"
              type="text"
              valueIsNumericString={true}
              format="##########"
              className="input input-bordered w-full pl-10"
              value={formData.phone}
              onValueChange={(values) => onEditValueChange(values, 'phone')}
              disabled={!isEditing}
            />
            <PhoneIcon className="new-customer-input-icon" />
          </div>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">อีเมล</span>
          </label>
          <div className="relative">
            <input
              type="text"
              placeholder="john@company.com"
              className="input input-bordered w-full pl-10"
              value={formData.email}
              onChange={(e) => onEditInputChange(e, 'email')}
              disabled={!isEditing}
            />
            <AtSymbolIcon className="new-customer-input-icon" />
          </div>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">LINE ID</span>
          </label>
          <div className="relative">
            <input
              type="text"
              className="input input-bordered w-full pl-10"
              value={formData.line}
              onChange={(e) => onEditInputChange(e, 'line')}
              disabled={!isEditing}
            />
            <svg
              className="new-customer-input-icon fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path d="M272.1 204.2v71.1c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.1 0-2.1-.6-2.6-1.3l-32.6-44v42.2c0 1.8-1.4 3.2-3.2 3.2h-11.4c-1.8 0-3.2-1.4-3.2-3.2v-71.1c0-1.8 1.4-3.2 3.2-3.2H219c1 0 2.1.5 2.6 1.4l32.6 44v-42.2c0-1.8 1.4-3.2 3.2-3.2h11.4c1.8-.1 3.3 1.4 3.3 3.1zm-82-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 1.8 1.4 3.2 3.2 3.2h11.4c1.8 0 3.2-1.4 3.2-3.2v-71.1c0-1.7-1.4-3.2-3.2-3.2zm-27.5 59.6h-31.1v-56.4c0-1.8-1.4-3.2-3.2-3.2h-11.4c-1.8 0-3.2 1.4-3.2 3.2v71.1c0 .9.3 1.6.9 2.2.6.5 1.3.9 2.2.9h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.7-1.4-3.2-3.1-3.2zM332.1 201h-45.7c-1.7 0-3.2 1.4-3.2 3.2v71.1c0 1.7 1.4 3.2 3.2 3.2h45.7c1.8 0 3.2-1.4 3.2-3.2v-11.4c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2V234c0-1.8-1.4-3.2-3.2-3.2H301v-12h31.1c1.8 0 3.2-1.4 3.2-3.2v-11.4c-.1-1.7-1.5-3.2-3.2-3.2zM448 113.7V399c-.1 44.8-36.8 81.1-81.7 81H81c-44.8-.1-81.1-36.9-81-81.7V113c.1-44.8 36.9-81.1 81.7-81H367c44.8.1 81.1 36.8 81 81.7zm-61.6 122.6c0-73-73.2-132.4-163.1-132.4-89.9 0-163.1 59.4-163.1 132.4 0 65.4 58 120.2 136.4 130.6 19.1 4.1 16.9 11.1 12.6 36.8-.7 4.1-3.3 16.1 14.1 8.8 17.4-7.3 93.9-55.3 128.2-94.7 23.6-26 34.9-52.3 34.9-81.5z" />
            </svg>
          </div>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">ชื่อบริษัท</span>
          </label>
          <div className="relative">
            <input
              type="text"
              className="input input-bordered w-full pl-10"
              value={formData.business_name}
              onChange={(e) => onEditInputChange(e, 'business_name')}
              disabled={!isEditing}
            />
            <BuildingOfficeIcon className="new-customer-input-icon" />
          </div>
        </div>
        <div className="flex space-x-2">
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">สาขา</span>
            </label>
            <input
              type="text"
              className="input input-bordered w-full"
              value={formData.branch}
              onChange={(e) => onEditInputChange(e, 'branch')}
              disabled={!isEditing}
            />
          </div>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text">เลขประจำตัวผู้เสียภาษี</span>
            </label>
            <PatternFormat
              displayType="input"
              type="text"
              valueIsNumericString={true}
              format="#-####-#####-##-#"
              className="input input-bordered w-full"
              value={formData.tax_id}
              onValueChange={(values) => onEditValueChange(values, 'tax_id')}
              disabled={!isEditing}
            />
          </div>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">ที่อยู่บริษัท</span>
          </label>
          <div className="relative">
            <input
              type="text"
              className="input input-bordered w-full pl-10"
              value={formData.address}
              onChange={(e) => onEditInputChange(e, 'address')}
              disabled={!isEditing}
            />
            <MapIcon className="new-customer-input-icon" />
          </div>
        </div>
      </div>
      {isNewCustomer ? (
        <div className="flex space-x-2">
          <div className="w-1/2">
            <button className="btn btn-block gap-2" onClick={onCreateCustomer}>
              สร้างลูกค้า
              <UserPlusIcon className="hidden h-4 w-4 sm:block" />
            </button>
          </div>
          <div className="w-1/2">
            <CreateQuotationToggle
              label="สร้างใบเสนอราคา"
              onSubmitClick={onCreateQuotationNewCustomer}
            />
          </div>
        </div>
      ) : (
        <>
          {isView && !isEditing ? (
            <div className="flex space-x-2">
              <div className="w-1/2">
                <button className="btn btn-block gap-2" onClick={() => setIsEditing(true)}>
                  แก้ไขข้อมูล
                  <PencilSquareIcon className="hidden h-4 w-4 sm:block" />
                </button>
              </div>
              <div className="w-1/2">
                <CreateQuotationToggle
                  label="สร้างใบเสนอราคา"
                  onSubmitClick={onCreateQuotationExistingCustomer}
                />
              </div>
            </div>
          ) : (
            <div className="flex space-x-2">
              <div className="w-1/2">
                <button className="btn btn-block gap-2" onClick={onEditCustomer}>
                  บันทึก
                  <ArrowDownOnSquareIcon className="hidden h-4 w-4 sm:block" />
                </button>
              </div>
              <div className="w-1/2">
                <button
                  className="btn btn-outline btn-block gap-2"
                  onClick={() => {
                    setIsEditing(false);
                    setFormData(initialFormData);
                  }}
                >
                  ยกเลิก
                  <XMarkIcon className="hidden h-4 w-4 sm:block" />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomerForm;

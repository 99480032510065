import { buttonColor, ConfirmationStyleProps } from './ConfirmationToggle';

interface IConfirmationPopup extends ConfirmationStyleProps {
  togglePopup: () => void;
  popupId: string;
  onSubmitClick: () => void;
}

const ConfirmationPopup = (props: IConfirmationPopup) => {
  const { togglePopup, popupId, onSubmitClick, title, submitLabel, submitButtonColor } = props;

  const onSubmit = () => {
    onSubmitClick();
    togglePopup();
  };
  return (
    <>
      <input type="checkbox" id={popupId} className="modal-toggle" />
      <div className="modal modal-bottom text-center sm:modal-middle">
        <div className="modal-box">
          <h3 className="text-lg font-bold">{title}</h3>
          <div className="modal-action">
            <div className="w-1/2">
              <label className="btn btn-block" onClick={togglePopup}>
                ยกเลิก
              </label>
            </div>
            <div className="w-1/2">
              <label
                className={`btn btn-block text-white ${
                  submitButtonColor ? buttonColor[submitButtonColor] : ''
                }`}
                onClick={onSubmit}
              >
                {submitLabel}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationPopup;

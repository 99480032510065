import { PaperAirplaneIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { IQuotationMFO } from '../../../interfaces/quotation';
import CreateMFOPopup, { ICreateMFOFormData } from './CreateMFOPopup';

interface ICreateMFOToggleProps {
  onSubmitClick: (formData: ICreateMFOFormData) => Promise<void>;
  isEdit?: boolean;
  mfo?: IQuotationMFO;
}

const CreateMFOToggle = (props: ICreateMFOToggleProps) => {
  const { onSubmitClick, isEdit = false, mfo } = props;
  const [isOpen, setIsOpen] = useState(false);
  const popupId = 'create_mfo_popup';
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <label className="btn gap-2" htmlFor={popupId} onClick={togglePopup}>
        {isEdit ? (
          <>
            แก้ไข MFO
            <PencilSquareIcon className="hidden h-4 w-4 sm:block" />
          </>
        ) : (
          <>
            สร้าง MFO
            <PaperAirplaneIcon className="hidden h-4 w-4 sm:block" />
          </>
        )}
      </label>
      {isOpen && (
        <>
          <CreateMFOPopup
            popupId={popupId}
            togglePopup={togglePopup}
            onSubmitClick={onSubmitClick}
            isEdit={isEdit}
            mfo={mfo}
          />
        </>
      )}
    </>
  );
};

export default CreateMFOToggle;

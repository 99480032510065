import formatStringByPattern from 'format-string-by-pattern';

export const numberFormatter = (digits: number) => {
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const formatTaxId = (taxId: number | string) => {
  return formatStringByPattern('0-0000-00000-00-0', taxId);
};

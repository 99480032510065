import { MakeGenerics, Route } from '@tanstack/react-location';
import { Cookies } from 'react-cookie';

import { queryClient } from './App';
import { fetchCustomerById, fetchCustomers } from './hooks/customer';
import { fetchInvoiceByNo, fetchInvoiceItem, fetchInvoices } from './hooks/invoice';
import { fetchProduct, fetchProductsByCategory, fetchProductsCategories } from './hooks/product';
import { fetchQuotationById, fetchQuotationPrintById, fetchQuotations } from './hooks/quotation';
import { fetchStaffs } from './hooks/staff';
import { IProductsCategoriesResponse, IProductType } from './interfaces/product';
import Customer from './pages/Customer/Customer';
import Customers from './pages/Customer/Customers';
import Home from './pages/Home';
import Invoice from './pages/Invoice/Invoice';
import InvoiceItem from './pages/Invoice/InvoiceItem';
import Invoices from './pages/Invoice/Invoices';
import Product from './pages/Product/Product';
import Quotation from './pages/Quotation/Quotation';
import QuotationDelivery from './pages/Quotation/QuotationDelivery';
import QuotationMFOPrint from './pages/Quotation/QuotationMFOPrint';
import QuotationPreviewMFO from './pages/Quotation/QuotationPreviewMFO';
import QuotationPrint from './pages/Quotation/QuotationPrint';
import Quotations from './pages/Quotation/Quotations';

export type RouteLocationGenerics = MakeGenerics<{
  Params: {
    invoiceNo: string;
    quotationId: string;
    customerId: string;
    productType: IProductType;
    productId: string;
    mfoId: string;
    uuid: string;
  };
  Search: {
    quotationId: string;
  };
}>;

export const routes: Route<RouteLocationGenerics>[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/invoices',
    children: [
      {
        path: '/',
        element: <Invoices />,
        loader: async () => {
          const from = '2020-01-01';
          const to = '2030-12-31';
          const queryKey = ['invoices', from, to];

          return (
            queryClient.getQueryData(queryKey) ??
            queryClient.fetchQuery(queryKey, () => fetchInvoices(from, to)).then(() => ({}))
          );
        },
      },
      {
        path: ':invoiceNo/items/:uuid',
        element: <InvoiceItem />,
        loader: ({ params: { invoiceNo, uuid } }) =>
          queryClient.getQueryData(['invoices', invoiceNo]) ??
          queryClient.fetchQuery(['invoices', invoiceNo], () => fetchInvoiceItem(invoiceNo, uuid)),
      },
      {
        path: ':invoiceNo',
        element: <Invoice />,
        loader: ({ params: { invoiceNo } }) =>
          queryClient.getQueryData(['invoices', invoiceNo]) ??
          queryClient.fetchQuery(['invoices', invoiceNo], () => fetchInvoiceByNo(invoiceNo)),
      },
    ],
  },
  {
    path: '/quotations',
    children: [
      {
        path: '/',
        element: <Quotations />,
        loader: () =>
          queryClient.getQueryData(['quotations']) ??
          queryClient.fetchQuery(['quotations'], fetchQuotations).then(() => ({})),
      },
      {
        path: 'mfo/:mfoId/print',
        element: <QuotationMFOPrint />,
      },
      {
        path: ':quotationId/',
        element: <Quotation />,
        loader: ({ params: { quotationId } }) => {
          queryClient.getQueryData(['staffs']) ??
            queryClient.fetchQuery(['staffs'], fetchStaffs).then(() => ({}));
          queryClient.getQueryData(['products', 'categories']) ??
            queryClient
              .fetchQuery<IProductsCategoriesResponse[]>(['products', 'categories'], ({ signal }) =>
                fetchProductsCategories(signal)
              )
              .then((data) => {
                data.forEach((category) => {
                  queryClient.getQueryData(['products', 'categories', `${category.id}`]) ??
                    queryClient.fetchQuery(['products', 'categories', `${category.id}`], () =>
                      fetchProductsByCategory(category.id)
                    );
                });
                return {};
              });
          return (
            queryClient.getQueryData(['quotations', quotationId]) ??
            queryClient.fetchQuery(['quotations', quotationId], () =>
              fetchQuotationById(quotationId)
            )
          );
        },
      },
      {
        path: ':quotationId/print',
        element: <QuotationPrint />,
        loader: ({ params: { quotationId } }) => {
          queryClient.getQueryData(['quotations', quotationId]) ??
            queryClient.fetchQuery(['quotations', quotationId], () =>
              fetchQuotationById(quotationId)
            );
          return (
            queryClient.getQueryData(['quotations', 'products', quotationId, 'print']) ??
            queryClient.fetchQuery(['quotations', 'products', quotationId, 'print'], () =>
              fetchQuotationPrintById(quotationId)
            )
          );
        },
      },
      {
        path: ':quotationId/delivery',
        element: <QuotationDelivery />,
        loader: ({ params: { quotationId } }) => {
          queryClient.getQueryData(['quotations', quotationId]) ??
            queryClient.fetchQuery(['quotations', quotationId], () =>
              fetchQuotationById(quotationId)
            );
          return (
            queryClient.getQueryData(['quotations', 'products', quotationId, 'print']) ??
            queryClient.fetchQuery(['quotations', 'products', quotationId, 'print'], () =>
              fetchQuotationPrintById(quotationId)
            )
          );
        },
      },
      {
        path: ':quotationId/preview-mfo',
        element: <QuotationPreviewMFO />,
        loader: ({ params: { quotationId } }) => {
          queryClient.getQueryData(['quotations', quotationId]) ??
            queryClient.fetchQuery(['quotations', quotationId], () =>
              fetchQuotationById(quotationId)
            );
          return (
            queryClient.getQueryData(['quotations', 'products', quotationId, 'print']) ??
            queryClient.fetchQuery(['quotations', 'products', quotationId, 'print'], () =>
              fetchQuotationPrintById(quotationId)
            )
          );
        },
      },
      {
        path: ':quotationId/:productType/:productId',
        element: <Product />,
        loader: ({ params: { productType, productId } }) =>
          queryClient.getQueryData(['products', 'type', productType, productId]) ??
          queryClient.fetchQuery(['products', 'type', productType, productId], () =>
            fetchProduct(productType, productId)
          ),
      },
    ],
  },
  {
    path: '/products',
    children: [
      {
        path: ':productType/:productId',
        element: <Product />,
        loader: ({ params: { productType, productId } }) =>
          queryClient.getQueryData(['products', 'type', productType, productId]) ??
          queryClient.fetchQuery(['products', 'type', productType, productId], () =>
            fetchProduct(productType, productId)
          ),
      },
    ],
  },
  {
    path: '/customers',
    children: [
      {
        path: '/',
        element: <Customers />,
        loader: () => {
          const cookies = new Cookies();
          cookies.set('customer-active-tab', '');
          const filterString = cookies.get('customer-filter');
          return (
            queryClient.getQueryData(['customers', 'filter', filterString]) ??
            queryClient
              .fetchQuery(['customers', 'filter', filterString], () => fetchCustomers(filterString))
              .then(() => ({}))
          );
        },
      },
      {
        path: '/new',
        element: <Customers />,
        loader: () => {
          const cookies = new Cookies();
          cookies.set('customer-active-tab', 'NEW_CUSTOMER');
          const filterString = cookies.get('customer-filter');
          return (
            queryClient.getQueryData(['customers', 'filter', filterString]) ??
            queryClient
              .fetchQuery(['customers', 'filter', filterString], () => fetchCustomers(filterString))
              .then(() => ({}))
          );
        },
      },
      {
        path: ':customerId',
        element: <Customer />,
        loader: ({ params: { customerId } }) =>
          queryClient.getQueryData(['customers', customerId]) ??
          queryClient.fetchQuery(['customers', customerId], () => fetchCustomerById(customerId)),
      },
    ],
  },
];

import { forwardRef, LegacyRef, useEffect, useState } from 'react';

import logo from '../../images/rk-logo.png';
import { IInvoice, IInvoiceItem } from '../../interfaces/invoice';
import { ArabicNumberToText } from '../../utils/thaibath';

interface Props {
  invoiceInfo: IInvoice;
  items: IInvoiceItem[];
}

const InvoicePdf = forwardRef((props: Props, ref) => {
  const { invoiceInfo, items } = props;

  const months = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];
  const invoiceDateSplit = invoiceInfo.date.split('-');
  const invoiceDay = `${parseInt(invoiceDateSplit[2])}`;
  const invoiceMonth = `${months[parseInt(invoiceDateSplit[1], 10) - 1]}`;
  const invoiceYear = `พ.ศ. ${parseInt(invoiceDateSplit[0], 10) + 543}`;

  const [tableSummary, setTableSummary] = useState({
    total: 0,
    vat: 0,
    totalIncVat: 0,
  });

  useEffect(() => {
    setTableSummary(() => {
      const total = items.reduce((accumulator, curr) => {
        return accumulator + curr.price;
      }, 0);
      const vat = total * 0.07;
      const totalIncVat = total + vat;
      return {
        total,
        vat,
        totalIncVat,
      };
    });
  }, [items]);

  const numberFormatter = (digits: number) => {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    });
  };
  return (
    // <div
    //   className="shadow-lg relative w-[210mm] min-h-[297mm] pt-[20mm] pr-[16mm] pb-[20mm] pl-[16mm] text-[12px] font-sarabun"
    //   ref={ref as LegacyRef<HTMLDivElement>}
    // >
    <div
      className="relative w-[210mm] pt-[20mm] pr-[16mm] pb-[20mm] pl-[16mm] font-sarabun text-[12px]"
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      <div className="flex">
        <div className="w-1/4">
          <img src={logo} alt="RK Steel" />
        </div>
        <div className="flex-1 text-center">
          <h1 className="mb-2 text-lg font-bold">บริษัท เหล็กร่มเกล้า จำกัด (สำนักงานใหญ่)</h1>
          <p className="mb-1">
            93 หมู่ที่ 6 ตำบลคลองหลวงแพ่ง อำเภอเมืองฉะเชิงเทรา จังหวัดฉะเชิงเทรา 24000
          </p>
          <p>โทร. 097-081-7700 เลขประจำตัวผู้เสียภาษี 0105559029172</p>
        </div>
      </div>
      <h2 className="mt-4 mb-2 text-center text-base">
        ต้นฉบับใบกำกับภาษี/ใบเสร็จรับเงิน/ใบส่งของ
      </h2>
      <div>
        <div className="w-full space-y-2">
          <div className="flex items-center space-x-2 text-right">
            <div className="flex-1">
              <span>เลขที่</span>
            </div>
            <div className="w-[130px]">
              <span>{invoiceInfo.invoiceNo}</span>
            </div>
          </div>
          <div className="flex items-center space-x-2 text-right">
            <div className="flex-1">
              <span>วันที่</span>
            </div>
            <div className="w-[130px]">
              <span>
                {invoiceDay} {invoiceMonth} {invoiceYear}
              </span>
            </div>
          </div>
          <div className="flex space-y-2">
            <div className="flex w-full items-center space-x-2">
              <div className="w-[50px]">
                <span>นามผู้ซื้อ</span>
              </div>
              <div className="flex-1">
                <span>{invoiceInfo.name}</span>
              </div>
            </div>
          </div>
          <div className="flex space-y-2">
            <div className="flex w-full items-center space-x-2">
              <div className="w-[50px]">
                <span>สาขา</span>
              </div>
              <div className="flex-1">
                <span>{invoiceInfo.branch}</span>
              </div>
            </div>
            <div className="flex items-center space-x-2 text-right">
              <div className="w-56">
                <span>เลขประจำตัวผู้เสียภาษี</span>
              </div>
              <div className="w-[130px]">
                <span>{invoiceInfo.taxId}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-[50px]">
              <span>ที่อยู่</span>
            </div>
            <div className="flex-1">
              <span>{invoiceInfo.address}</span>
            </div>
          </div>
          {invoiceInfo.clientNote ? (
            <div className="flex items-center space-x-2">
              <div className="w-[50px]">
                <span>หมายเหตุ</span>
              </div>
              <div className="flex-1">
                <span>{invoiceInfo.clientNote}</span>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="mt-8 w-full">
          <table className="invoice-pdf w-full">
            <thead>
              <tr className="text-center">
                <th className="w-[48px]">ลำดับที่</th>
                <th className="">รายการ</th>
                <th className="w-[50px]">หน่วย</th>
                <th className="w-[80px]">จำนวน</th>
                <th className="w-[70px]">
                  ราคาต่อ
                  <br />
                  หน่วย
                </th>
                <th className="w-[60px]">ส่วนลด</th>
                <th className="w-[80px]">จำนวนเงิน</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, idx) => (
                <tr key={item.uuid}>
                  <td className="text-center">{idx + 1}</td>
                  <td>{item.name}</td>
                  <td className="text-center">{item.unit}</td>
                  <td className="text-right">{numberFormatter(4).format(item.amount)}</td>
                  <td className="text-right">{numberFormatter(2).format(item.pricePerUnit)}</td>
                  <td className="text-right">
                    {item.discount ? numberFormatter(2).format(item.discount) : ''}
                  </td>
                  <td className="text-right">{numberFormatter(2).format(item.price)}</td>
                </tr>
              ))}
              <tr>
                <td rowSpan={3} colSpan={4} className="text-center align-bottom">
                  ({ArabicNumberToText(numberFormatter(2).format(tableSummary.totalIncVat))})
                </td>
                <td colSpan={2} className="!border-y-0 !border-none">
                  จำนวนเงินรวม
                </td>
                <td className="text-right">{numberFormatter(2).format(tableSummary.total)}</td>
              </tr>
              <tr>
                <td colSpan={2} className="!border-y-0">
                  ภาษีมูลค่าเพิ่ม 7%
                </td>
                <td className="text-right">{numberFormatter(2).format(tableSummary.vat)}</td>
              </tr>
              <tr>
                <td colSpan={2} className="!border-t-0">
                  รวมทั้งสิ้น
                </td>
                <td className="text-right">
                  {numberFormatter(2).format(tableSummary.totalIncVat)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-12 space-y-2">
          <div className="ml-auto flex w-48 items-end space-x-1">
            <span>ผู้รับเงิน</span>
            <div className="h-[1px] flex-1 border-b border-solid border-black"></div>
          </div>
          <div className="ml-auto flex w-48 items-end space-x-1">
            <span>วันที่</span>
            <div className="h-[1px] flex-1 border-b border-solid border-black"></div>
          </div>
        </div>
      </div>
    </div>
  );
});

InvoicePdf.displayName = 'InvoicePdf';

export default InvoicePdf;

import { Prefix } from '../interfaces/customer';

export const prefixMapper = (prefix: Prefix) => {
  if (prefix === 'other') {
    return prefix;
  }

  const prefixes = {
    mr: 'คุณ',
    org: 'บริษัท',
    engineer: 'ช่าง',
    other: 'อื่นๆ',
  };

  return prefixes[prefix];
};

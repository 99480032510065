import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ICustomerData, ICustomerDataResponse, ICustomerQuotation } from '../interfaces/customer';
import { UseQueryError } from '../interfaces/utils';
import { useDebounce } from './useDebounce';

export const fetchCustomers = async (filter = '') => {
  const { data } = await axios.get(`/customers/search/?q=${filter}`);
  return data;
};

// ref: https://stackoverflow.com/a/67927848
export function useCustomers(filter = '') {
  const debouncedFilter = useDebounce(filter, 500);
  return useQuery<ICustomerDataResponse[], UseQueryError>(
    ['customers', 'filter', debouncedFilter],
    () => fetchCustomers(debouncedFilter),
    { enabled: Boolean(debouncedFilter) }
  );
}

export const fetchCustomerById = async (id: string) => {
  const { data } = await axios.get(`/customers/${id}/`);
  return data;
};

export const fetchCustomerByPhone = async (phone: string) => {
  const { data } = await axios.get(`/customers/search_phone/?phone=${phone}`);
  return data;
};

export function useCustomer(customerId: string) {
  return useQuery<ICustomerDataResponse, UseQueryError>(
    ['customers', customerId],
    () => fetchCustomerById(customerId),
    {
      enabled: !!customerId,
    }
  );
}

export const fetchCustomerQuotationsById = async (id: string) => {
  const { data } = await axios.get(`/customers/${id}/quotations/`);
  return data;
};

export function useCustomerQuotations(customerId: string) {
  return useQuery<ICustomerQuotation[], UseQueryError>(
    ['customers', customerId, 'quotations'],
    () => fetchCustomerQuotationsById(customerId),
    {
      enabled: !!customerId,
    }
  );
}

export const postCreateCustomer = async (formData: ICustomerData) => {
  const { data } = await axios.post<ICustomerDataResponse>('/customers/', formData);
  return data;
};

export const putEditCustomer = async (formData: ICustomerData, id: string) => {
  const { data } = await axios.put<ICustomerDataResponse>(`/customers/${id}/`, formData);
  return data;
};

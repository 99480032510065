import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { IInvoice, IInvoiceItem } from '../interfaces/invoice';
import { UseQueryError } from '../interfaces/utils';
import { getProductInfo } from './product';

const baseUrl = 'https://xg55860f75.execute-api.ap-southeast-1.amazonaws.com/dev/invoices';

export async function fetchInvoices(from: string, to: string): Promise<IInvoice[]> {
  const { data } = await axios.get(
    `${baseUrl}?sort=createdAt&from=${from}&to=${to}&dateField=date`
  );
  return data.data.data;
}

export function useInvoices(from: string, to: string) {
  return useQuery<IInvoice[], UseQueryError>(['invoices', from, to], () => fetchInvoices(from, to));
}

export async function createInvoice(date: string, amount: number) {
  const { data } = await axios.post(`${baseUrl}`, { date, amount });
  return data;
}

export async function updateEtax(id: string, etaxPdf: string, etaxXml: string) {
  const { data } = await axios.put(`${baseUrl}/${id}`, {
    etaxPdf,
    etaxXml,
  });
  return data;
}

export async function updateInvoice(
  id: string,
  taxId: string,
  name: string,
  address: string,
  branch: string,
  date: string,
  bankAmount: number,
  note: string,
  clientNote: string,
  printed: boolean,
  checked: boolean,
  email: string
) {
  const { data } = await axios.put(`${baseUrl}/${id}`, {
    taxId,
    name,
    address,
    branch,
    date,
    bankAmount,
    note,
    clientNote,
    printed,
    checked,
    email,
  });
  return data;
}

export async function importQuotation(id: string, quotationId: string) {
  const { data } = await axios.post(`${baseUrl}/${id}/import`, { quotationId });
  return data;
}

export async function addInvoiceItem(id: string, productType: string, productId: number) {
  const { data } = await axios.post(`${baseUrl}/${id}/items`, { productType, productId });
  return data;
}

export const fetchInvoiceItem = async (id: string, uuid: string) => {
  const { data } = await axios.get(`${baseUrl}/${id}/items/${uuid}`);
  const productData = await getProductInfo(data.data.productType, data.data.productId);
  data.data.product = productData;
  return data.data;
};

export const deleteInvoiceItem = async (id: string, uuid: string) => {
  const { data } = await axios.delete(`${baseUrl}/${id}/items/${uuid}`);
  return data.data;
};

export const updateInvoiceItem = async (
  id: string,
  uuid: string,
  invoiceItemData: IInvoiceItem
) => {
  const { data } = await axios.put(`${baseUrl}/${id}/items/${uuid}`, { ...invoiceItemData });
  return data.data;
};

export function useInvoiceItem(invoiceNo: string, uuid: string) {
  return useQuery<IInvoiceItem, UseQueryError>(['invoices', invoiceNo], () =>
    fetchInvoiceItem(invoiceNo, uuid)
  );
}

export const fetchInvoiceByNo = async (id: string) => {
  const { data } = await axios.get(`${baseUrl}/${id}`);
  return data.data;
};

export function useInvoice(invoiceNo: string) {
  return useQuery<IInvoice, UseQueryError>(
    ['invoices', invoiceNo],
    () => fetchInvoiceByNo(invoiceNo),
    {
      enabled: !!invoiceNo,
    }
  );
}

import { ReactLocation, Router } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';

import Layout from './components/Layout';
import { RouteLocationGenerics, routes } from './routes';

const reactLocation = new ReactLocation<RouteLocationGenerics>();
export const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Router location={reactLocation} routes={routes}>
          <Layout />
          {import.meta.env.DEV && <ReactLocationDevtools initialIsOpen={false} />}
        </Router>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;

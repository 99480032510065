import { useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect } from 'react';
import { toast } from 'react-toastify';
import { pick } from 'underscore';

import { useUpdateNormalProduct } from '../../hooks/product';
import { INormalProductConfig } from '../../interfaces/product';
import { IQuotationProductNormal } from '../../interfaces/quotation';
import { IProductBaseProps } from '../../pages/Product/Product';
import { ActionType, useProductFormReducer } from '../../reducers/productFormReducer';
import { getImageUrl } from '../../utils';
import { numberFormatter } from '../../utils/formatter';

interface IProductNormalFormProps extends IProductBaseProps {
  data: IQuotationProductNormal;
}

const productConfigKeys = ['qty', 'option_note', 'total'];

const ProductNormalForm = (props: IProductNormalFormProps) => {
  const { data, productId, quotationId } = props;
  const { product } = data;
  const initialConfig = pick(data, productConfigKeys) as INormalProductConfig;

  const navigate = useNavigate();

  const [state, dispatch] = useProductFormReducer(initialConfig);

  const mutation = useUpdateNormalProduct();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: ActionType.INPUT_CHANGE,
      payload: {
        name: event.target.name,
        value: event.target.value,
      },
    });
  };

  const onSave = async () => {
    try {
      await mutation.mutateAsync({ formData: state, productId });
      navigate({ to: `/quotations/${quotationId}` });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };

  useEffect(() => {
    dispatch({
      type: ActionType.INPUT_CHANGE,
      payload: {
        name: 'total',
        value: state.qty * +product.price,
      },
    });
  }, [product, state.qty, dispatch]);
  return (
    <div className="flex flex-col items-center space-y-4">
      <figure className="max-w-xs">
        <img src={getImageUrl(product.image)} alt={product.name} className="rounded-xl" />
      </figure>
      <h2 className="text-xl font-bold">{product.name}</h2>
      <h3 className="text-lg font-bold">ราคา: {numberFormatter(2).format(+product.price)} บาท</h3>
      <div className="w-full space-y-2">
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">จำนวน</span>
          </label>
          <input
            type="number"
            placeholder="ex. 10"
            className="input input-bordered w-full"
            value={state['qty'] || ''}
            name="qty"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">Note</span>
          </label>
          <input
            type="text"
            placeholder="..."
            className="input input-bordered w-full"
            value={state['option_note']}
            name="option_note"
            onChange={(e) => handleInputChange(e)}
          />
        </div>
      </div>
      <div className="rounded-box w-full space-y-2 bg-base-300 p-4">
        <div className="flex space-x-2">
          <span className="flex-shrink-0 font-bold">สรุปราคา</span>
          <span className="w-full text-right">{numberFormatter(2).format(state.total)}</span>
          <span className="text-right">บาท</span>
        </div>
      </div>
      <div className="flex w-full space-x-2">
        <button className="btn btn-block" onClick={onSave}>
          บันทึก
        </button>
      </div>
    </div>
  );
};

export default ProductNormalForm;

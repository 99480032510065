import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { ICreateMFOFormData } from '../components/Popup/CreateMFO/CreateMFOPopup';
import {
  ICartProduct,
  ICreateQuotationBody,
  IQuotationMFO,
  IQuotationMFOPrint,
  IQuotationPrint,
  IQuotationProductAddBody,
  IQuotationProductAddResponse,
  IQuotationResponse,
} from '../interfaces/quotation';
import { UseQueryError } from '../interfaces/utils';

export async function fetchQuotations() {
  await new Promise((r) => setTimeout(r, 500));
  const { data } = await axios.get('/quotations/');
  return data;
}

export function useQuotations() {
  return useQuery<IQuotationResponse[], UseQueryError>(['quotations'], fetchQuotations);
}

export const fetchQuotationById = async (quotationId: string) => {
  const { data } = await axios.get(`/quotations/${quotationId}/`);
  return data;
};

export function useQuotation(quotationId: string) {
  return useQuery<IQuotationResponse, UseQueryError>(
    ['quotations', quotationId],
    () => fetchQuotationById(quotationId),
    {
      enabled: !!quotationId,
    }
  );
}

export const fetchProductsByQuotationId = async (quotationId: string) => {
  const { data } = await axios.get(`/quotations/${quotationId}/products/`);
  return data;
};

export function useQuotationProducts(quotationId: string) {
  return useQuery<ICartProduct[], UseQueryError>(
    ['quotations', 'products', quotationId],
    () => fetchProductsByQuotationId(quotationId),
    {
      enabled: !!quotationId,
    }
  );
}

export const fetchQuotationPrintById = async (quotationId: string) => {
  const { data } = await axios.get(`/quotations/${quotationId}/print/`);
  return data;
};

export function useQuotationProductPrint(quotationId: string) {
  return useQuery<IQuotationPrint, UseQueryError>(
    ['quotations', 'products', quotationId, 'print'],
    () => fetchQuotationPrintById(quotationId),
    {
      enabled: !!quotationId,
    }
  );
}

export const postCreateQuotation = async (formData: ICreateQuotationBody) => {
  const { data } = await axios.post<IQuotationResponse>('/quotations/', formData);
  return data;
};

interface IUseCopyQuotation {
  quotationId: string;
}

export const useCopyQuotation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ quotationId }: IUseCopyQuotation) =>
      axios.post<{ running_number: number }>(`/quotations/${quotationId}/copy/`),
    {
      onSettled: async (data, error) => {
        if (!error) {
          await queryClient.invalidateQueries(['quotations', data?.data.running_number]);
        }
      },
    }
  );
};

interface IUseUpdateQuotation {
  quotationId: string;
  staffId: number;
  note: string;
  customerName: string;
}

export const useUpdateQuotation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ staffId, note, customerName, quotationId }: IUseUpdateQuotation) =>
      axios.patch<{ pk: number }>(`/quotations/${quotationId}/`, {
        staff_id: staffId,
        note: note,
        customer_name: customerName,
      }),
    {
      onSettled: async (data, error, { quotationId }) => {
        await queryClient.invalidateQueries(['quotations', quotationId]);
      },
    }
  );
};

interface IUseAddProductVars {
  formData: IQuotationProductAddBody;
  quotationId: string;
  productType: string;
}

export const useAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formData, quotationId, productType }: IUseAddProductVars) =>
      axios.post<IQuotationProductAddResponse>(
        `/quotations/${quotationId}/add_${productType}/`,
        formData
      ),
    {
      onSettled: async (data, error, { quotationId }) => {
        await queryClient.invalidateQueries(['quotations', 'products', quotationId]);
      },
    }
  );
};

interface IUseDeleteProductVars {
  quotationId: string;
  productId: number;
  productType: string;
}

export const useDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ productId, productType }: IUseDeleteProductVars) =>
      axios.delete(`/quotations/${productType}/${productId}/`),
    {
      onSettled: async (data, error, { quotationId }) => {
        await queryClient.invalidateQueries(['quotations', 'products', quotationId]);
      },
    }
  );
};

export const fetchMFOById = async (mfoId: string) => {
  const { data } = await axios.get(`/mfos/${mfoId}/`);
  return data;
};

export function useMFO(mfoId: string) {
  return useQuery<IQuotationMFO, UseQueryError>(
    ['quotations', 'mfos', mfoId],
    () => fetchMFOById(mfoId),
    {
      enabled: !!mfoId,
    }
  );
}

export const fetchMFOPrintById = async (mfoId: string) => {
  const { data } = await axios.get(`/mfos/${mfoId}/print/`);
  return data;
};

export function useMFOPrint(mfoId: string) {
  return useQuery<IQuotationMFOPrint, UseQueryError>(
    ['quotations', 'mfos', mfoId, 'print'],
    () => fetchMFOPrintById(mfoId),
    {
      enabled: !!mfoId,
    }
  );
}

interface IUseAddMFOVars {
  formData: ICreateMFOFormData;
  quotationId: string;
}

export const useAddMFO = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formData, quotationId }: IUseAddMFOVars) => {
      console.log('Making POST request to add MFO', { quotationId, formData });
      return axios.post<{ running_number: number }>(`/quotations/${quotationId}/mfo/`, formData);
    },
    {
      onSettled: async (data) => {
        await queryClient.invalidateQueries([
          'quotations',
          'mfos',
          data?.data.running_number,
          'print',
        ]);
        await queryClient.invalidateQueries(['quotations', 'mfos', data?.data.running_number]);
      },
    }
  );
};

interface IUseUpdateMFO {
  mfoId: string;
  formData: ICreateMFOFormData;
}

export const useUpdateMFO = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ mfoId, formData }: IUseUpdateMFO) => axios.patch<IQuotationMFO>(`/mfos/${mfoId}/`, formData),
    {
      onSettled: async (data, error, { mfoId }) => {
        await queryClient.invalidateQueries(['quotations', 'mfos', mfoId, 'print']);
        await queryClient.invalidateQueries(['quotations', 'mfos', mfoId]);
      },
    }
  );
};

interface IUseCreateTrelloVars {
  mfoId: string;
  formData: {
    image: string;
  };
}

export const useCreateTrello = () => {
  return useMutation(({ mfoId, formData }: IUseCreateTrelloVars) =>
    axios.post<{ pk: number }>(`/mfos/${mfoId}/trello/`, formData)
  );
};

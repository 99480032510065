// https://github.com/tpsumeta/ThaiBath/blob/master/thaibath.js

export function ThaiNumberToText(numb: string) {
  numb = numb.replace(/๐/gi, '0');
  numb = numb.replace(/๑/gi, '1');
  numb = numb.replace(/๒/gi, '2');
  numb = numb.replace(/๓/gi, '3');
  numb = numb.replace(/๔/gi, '4');
  numb = numb.replace(/๕/gi, '5');
  numb = numb.replace(/๖/gi, '6');
  numb = numb.replace(/๗/gi, '7');
  numb = numb.replace(/๘/gi, '8');
  numb = numb.replace(/๙/gi, '9');
  return ArabicNumberToText(numb);
}

export function ArabicNumberToText(value: string) {
  const numb = CheckNumber(value);
  const numberArray = [
    'ศูนย์',
    'หนึ่ง',
    'สอง',
    'สาม',
    'สี่',
    'ห้า',
    'หก',
    'เจ็ด',
    'แปด',
    'เก้า',
    'สิบ',
  ];
  const digitArray = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];
  let bahtText = '';
  if (isNaN(Number(numb))) {
    return 'ข้อมูลนำเข้าไม่ถูกต้อง';
  } else {
    if (Number(numb) - 0 > 9999999.9999) {
      return 'ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้';
    } else {
      const numbStrArr = numb.split('.');
      if (numbStrArr[1].length > 0) {
        numbStrArr[1] = numbStrArr[1].substring(0, 2);
      }
      const NumberLen = numbStrArr[0].length - 0;
      for (let i = 0; i < NumberLen; i++) {
        const tmp = Number(numbStrArr[0].substring(i, i + 1)) - 0;
        if (tmp !== 0) {
          if (i === NumberLen - 1 && tmp === 1) {
            bahtText += 'เอ็ด';
          } else if (i === NumberLen - 2 && tmp === 2) {
            bahtText += 'ยี่';
          } else if (i === NumberLen - 2 && tmp === 1) {
            bahtText += '';
          } else {
            bahtText += numberArray[tmp];
          }
          bahtText += digitArray[NumberLen - i - 1];
        }
      }
      bahtText += 'บาท';
      if (numbStrArr[1] === '0' || numbStrArr[1] === '00') {
        bahtText += 'ถ้วน';
      } else {
        const DecimalLen = numbStrArr[1].length - 0;
        for (let i = 0; i < DecimalLen; i++) {
          const tmp = Number(numbStrArr[1].substring(i, i + 1)) - 0;
          if (tmp !== 0) {
            if (i === DecimalLen - 1 && tmp === 1) {
              bahtText += 'เอ็ด';
            } else if (i === DecimalLen - 2 && tmp === 2) {
              bahtText += 'ยี่';
            } else if (i === DecimalLen - 2 && tmp === 1) {
              bahtText += '';
            } else {
              bahtText += numberArray[tmp];
            }
            bahtText += digitArray[DecimalLen - i - 1];
          }
        }
        bahtText += 'สตางค์';
      }
      return bahtText;
    }
  }
}

function CheckNumber(val: string) {
  let decimal = false;
  let valString = val.toString();
  valString = valString.replace(/ |,|บาท|฿/gi, '');
  for (let i = 0; i < valString.length; i++) {
    if (valString[i] === '.') {
      decimal = true;
    }
  }
  if (decimal === false) {
    valString = valString + '.00';
  }
  return valString;
}

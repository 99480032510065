import { IProductCalculationTypes, IProductType } from '../../interfaces/product';
import { ICartProduct } from '../../interfaces/quotation';
import { getImageUrl } from '../../utils';

interface IProductImageProps {
  product: ICartProduct;
}

const ProductImage = (props: IProductImageProps) => {
  const { product } = props;
  return (
    <div>
      {product.type === IProductType.OTHER ? (
        <>
          {product.product__calculation_type === IProductCalculationTypes.PERCENT ? (
            <svg
              className="mx-auto h-7 w-12 fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path d="M374.6 73.39c-12.5-12.5-32.75-12.5-45.25 0l-320 320c-12.5 12.5-12.5 32.75 0 45.25C15.63 444.9 23.81 448 32 448s16.38-3.125 22.62-9.375l320-320C387.1 106.1 387.1 85.89 374.6 73.39zM64 192c35.3 0 64-28.72 64-64S99.3 64.01 64 64.01S0 92.73 0 128S28.7 192 64 192zM320 320c-35.3 0-64 28.72-64 64s28.7 64 64 64s64-28.72 64-64S355.3 320 320 320z" />
            </svg>
          ) : (
            <svg
              className="mx-auto h-7 w-12 fill-current text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M176 32V64C237.9 64 288 114.1 288 176C288 200.2 280.3 222.6 267.3 240.9C298.9 260.7 320 295.9 320 336C320 397.9 269.9 448 208 448H176V480C176 497.7 161.7 512 144 512C126.3 512 112 497.7 112 480V448H41.74C18.69 448 0 429.3 0 406.3V101.6C0 80.82 16.82 64 37.57 64H112V32C112 14.33 126.3 0 144 0C161.7 0 176 14.33 176 32V32zM112 128H64V224H112V128zM224 176C224 149.5 202.5 128 176 128V224C202.5 224 224 202.5 224 176zM112 288H64V384H112V288zM208 384C234.5 384 256 362.5 256 336C256 309.5 234.5 288 208 288H176V384H208z" />
            </svg>
          )}
        </>
      ) : (
        <img
          src={getImageUrl(`media/${product.product__image}` || '')}
          alt={product.product__name}
          className="mx-auto w-12"
        />
      )}
    </div>
  );
};

export default ProductImage;

import {
  ArrowDownOnSquareIcon,
  DocumentDuplicateIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import { useMatch, useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import PageStatus from '../../components/PageStatus';
import ConfirmationToggle from '../../components/Popup/Confirmation/ConfirmationToggle';
import ProductCard from '../../components/Product/ProductCard';
import CartTable from '../../components/Quotation/CartTable';
import { useProductsByCategory, useProductsCategories } from '../../hooks/product';
import {
  useAddProduct,
  useCopyQuotation,
  useQuotation,
  useUpdateQuotation,
} from '../../hooks/quotation';
import { useStaffs } from '../../hooks/staff';
import { IProductType } from '../../interfaces/product';

const Quotation = () => {
  const {
    params: { quotationId },
  } = useMatch();
  const navigate = useNavigate();

  const { data: staffs } = useStaffs();
  const { data: quotation, status } = useQuotation(quotationId);
  const { data: productsCategories } = useProductsCategories();
  const categoryIds = useMemo(
    () => (productsCategories ? productsCategories.map((category) => category.id) : []),
    [productsCategories]
  );
  const productsByCategory = useProductsByCategory(categoryIds);
  const mutation = useAddProduct();
  const mutationQuotation = useUpdateQuotation();
  const mutationCopyQuotation = useCopyQuotation();

  const [selectedStaff, setSelectedStaff] = useState('-');
  const [customerName, setCustomerName] = useState('-');
  const [note, setNote] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (quotation) {
      setSelectedStaff(`${quotation.staff.id}`);
      setCustomerName(quotation.name);
      setNote(quotation.note);
    }
  }, [quotation]);

  useEffect(() => {
    if (categoryIds) {
      setActiveTab(categoryIds[0]);
    }
  }, [categoryIds]);

  const onAddProduct = async (productType: IProductType, productId: number) => {
    try {
      const { data } = await mutation.mutateAsync({
        formData: {
          product_id: productId,
        },
        productType,
        quotationId,
      });
      navigate({ to: `/quotations/${quotationId}/${productType}/${data.pk}` });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };

  const onUpdateQuotation = async () => {
    try {
      await mutationQuotation.mutateAsync({
        staffId: +selectedStaff,
        note: note,
        customerName: customerName,
        quotationId,
      });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };

  const onCopyQuotation = async () => {
    try {
      const newQuotation = await mutationCopyQuotation.mutateAsync({
        quotationId,
      });
      navigate({ to: `/quotations/${newQuotation.data.running_number}` });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };

  const onCustomerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomerName(event.target.value);
  };

  const onNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  if (status === 'loading') {
    return <PageStatus />;
  } else if (status === 'error') {
    return <PageStatus status="PAGE_NOT_FOUND" />;
  }

  return quotation ? (
    <div className="space-y-2">
      <div className="navbar rounded-box hidden bg-base-100 shadow-xl lg:flex">
        <div className="navbar-start"></div>
        <div className="navbar-end space-x-2">
          <ConfirmationToggle
            onSubmitClick={() => {
              onCopyQuotation();
            }}
            popupId="copy_quotation_popup"
            className="btn flex-nowrap gap-2 whitespace-nowrap"
            title="ยืนยันการ Copy Quotation"
            submitButtonColor="primary"
          >
            <>
              <span className="">Copy Quotation</span>
              <DocumentDuplicateIcon className="h-5 w-5" />
            </>
          </ConfirmationToggle>
          <a href={`/quotations/${quotationId}/print`} className="btn flex-nowrap gap-2 ">
            <span>พิมพ์</span>
            <PrinterIcon className="h-5 w-5" />
          </a>
        </div>
      </div>
      <div className="rounded-box space-y-4 bg-base-100 px-4 py-4 shadow-lg md:px-8">
        <div className="md:flex">
          <div className="relative w-full">
            <div className="flex items-center">
              <span className="w-16 flex-shrink-0">ลูกค้า: </span>
              <input
                type="text"
                className="input input-bordered input-sm"
                value={customerName}
                onChange={onCustomerNameChange}
              />
            </div>
            <div className="flex items-center">
              <span className="w-16 flex-shrink-0">Note: </span>
              <input
                type="text"
                className="input input-bordered input-sm w-full"
                value={note}
                onChange={onNoteChange}
              />
            </div>
            <div className="absolute right-0 top-0 space-x-2 lg:hidden">
              <ConfirmationToggle
                onSubmitClick={() => {
                  onCopyQuotation();
                }}
                popupId="copy_quotation_popup"
                className="btn btn-square btn-xs"
                title="ยืนยันการ Copy Quotation"
                submitButtonColor="primary"
              >
                <>
                  <DocumentDuplicateIcon className="h-4 w-4" />
                </>
              </ConfirmationToggle>
              <a href={`/quotations/${quotationId}/print`} className="btn btn-square btn-xs">
                <PrinterIcon className="h-4 w-4" />
              </a>
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-4">
              <span className="w-12 flex-shrink-0">ผู้ดูแล: </span>
              <select
                className="select select-bordered select-sm min-w-[12rem]"
                value={selectedStaff}
                onChange={(e) => setSelectedStaff(e.target.value)}
              >
                {staffs ? (
                  <>
                    {staffs.map((staff) => (
                      <option key={staff.id} value={staff.id}>
                        {staff.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="-">-</option>
                )}
              </select>
              <button className="btn btn-square btn-xs" onClick={() => onUpdateQuotation()}>
                <ArrowDownOnSquareIcon className="h-4 w-4" />
              </button>
            </div>
            <div className="flex items-center">
              <span className="w-16 flex-shrink-0">ID: </span>
              <span>#{quotation.running_number}</span>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="font-bold">รายการสินค้าที่เลือก</h3>
          <CartTable />
        </div>
        <div className="space-y-2">
          <h3 className="font-bold">สินค้า</h3>
          <div className="flex">
            {productsCategories && (
              <div className="tabs tabs-boxed shadow-lg">
                {productsCategories.map((category) => (
                  <button
                    key={category.id}
                    className={`tab ${activeTab === category.id ? 'tab-active' : ''}`}
                    onClick={() => setActiveTab(category.id)}
                  >
                    {category.name}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="grid grid-cols-3 gap-2 md:grid-cols-4 md:gap-4 lg:grid-cols-5 xl:grid-cols-6">
            {productsByCategory &&
              productsByCategory[categoryIds.findIndex((val) => val === activeTab)]?.data?.map(
                (prod) => (
                  <ProductCard
                    key={`${prod.type}_${prod[prod.type]?.id}`}
                    product={prod}
                    type={prod.type}
                    onAddProduct={onAddProduct}
                  />
                )
              )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Quotation;

import { useState } from 'react';

import { useStaffs } from '../../../hooks/staff';

interface ICreateQuotationPopup {
  togglePopup: () => void;
  popupId: string;
  onSubmitClick: (staffId: string) => Promise<void>;
}

const CreateQuotationPopup = (props: ICreateQuotationPopup) => {
  const { togglePopup, popupId, onSubmitClick } = props;

  const [selectedStaff, setSelectedStaff] = useState('1');

  const { data: staffs, isLoading: isStaffLoading } = useStaffs();
  const onSubmit = () => {
    onSubmitClick(selectedStaff);
    togglePopup();
  };
  return (
    <>
      <input type="checkbox" id={popupId} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <h3 className="text-lg font-bold">เลือกผู้ดูแล</h3>
          <div className="mt-4 w-full">
            <div className="form-control w-full">
              <select
                className="select select-bordered select-sm min-w-[12rem]"
                disabled={isStaffLoading}
                value={selectedStaff}
                onChange={(e) => setSelectedStaff(e.target.value)}
              >
                {staffs ? (
                  <>
                    {staffs.map((staff) => (
                      <option key={staff.id} value={staff.id}>
                        {staff.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <option value="-">-</option>
                )}
              </select>
            </div>
          </div>
          <div className="modal-action">
            <label className="btn btn-ghost" onClick={togglePopup}>
              Cancel
            </label>
            <label className="btn" onClick={onSubmit}>
              Submit
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuotationPopup;

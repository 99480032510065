import { ReactElement, useState } from 'react';

import ConfirmationPopup from './ConfirmationPopup';

export interface ConfirmationStyleProps {
  title?: string;
  submitLabel?: string;
  submitButtonColor?: keyof typeof buttonColor;
}

export enum buttonColor {
  primary = 'btn-primary',
  secondary = 'btn-secondary',
  accent = 'btn-accent',
  info = 'btn-info',
  success = 'btn-success',
  warning = 'btn-warning',
  error = 'btn-error',
  ghost = 'btn-ghost',
  link = 'btn-link',
}

interface IConfirmationToggleProps extends ConfirmationStyleProps {
  onSubmitClick: () => void;
  children: ReactElement;
  popupId: string;
  className?: string;
}

const ConfirmationToggle = (props: IConfirmationToggleProps) => {
  const {
    onSubmitClick,
    children,
    popupId = 'something',
    className = '',
    title = 'ยืนยันการทำรายการ',
    submitLabel = 'ยืนยัน',
    submitButtonColor,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <span>
      <label className={className} htmlFor={popupId} onClick={togglePopup}>
        {children}
      </label>
      {isOpen && (
        <>
          <ConfirmationPopup
            popupId={popupId}
            togglePopup={togglePopup}
            onSubmitClick={onSubmitClick}
            title={title}
            submitLabel={submitLabel}
            submitButtonColor={submitButtonColor}
          />
        </>
      )}
    </span>
  );
};

export default ConfirmationToggle;

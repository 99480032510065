import { useMatch } from '@tanstack/react-location';

import BackButton from '../../components/BackButton';
import PageStatus from '../../components/PageStatus';
import ProductNormalForm from '../../components/Product/ProductNormalForm';
import ProductOtherForm from '../../components/Product/ProductOtherForm';
import ProductWeightedForm from '../../components/Product/ProductWeightedForm';
import { useProduct } from '../../hooks/product';
import { IProductType } from '../../interfaces/product';
import {
  IQuotationProductNormal,
  IQuotationProductOther,
  IQuotationProductWeighted,
} from '../../interfaces/quotation';
import { RouteLocationGenerics } from '../../routes';

export interface IProductBaseProps {
  productId: string;
  quotationId: string;
}

const Product = () => {
  const {
    params: { productType, productId, quotationId },
  } = useMatch<RouteLocationGenerics>();

  const { data, status } = useProduct(productType, productId);
  return (
    <div className="mx-auto max-w-xl space-y-4">
      <BackButton to={`/quotations/${quotationId}`} />
      <div className="rounded-box bg-base-100 px-6 py-4 shadow-lg">
        {status === 'loading' ? (
          <PageStatus />
        ) : status === 'error' ? (
          <PageStatus status="ERROR" />
        ) : data ? (
          <>
            {productType === IProductType.WEIGHTED && (
              <ProductWeightedForm
                data={data as IQuotationProductWeighted}
                productId={productId}
                quotationId={quotationId}
              />
            )}
            {productType === IProductType.NORMAL && (
              <ProductNormalForm
                data={data as IQuotationProductNormal}
                productId={productId}
                quotationId={quotationId}
              />
            )}
            {productType === IProductType.OTHER && (
              <ProductOtherForm
                data={data as IQuotationProductOther}
                productId={productId}
                quotationId={quotationId}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Product;

import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { Link, useLoadRoute } from '@tanstack/react-location';

import { useInvoices } from '../../hooks/invoice';
import { numberFormatter } from '../../utils/formatter';
import PageStatus from '../PageStatus';

interface IInvoicesTable {
  from: string;
  to: string;
}

const InvoicesTable = (props: IInvoicesTable) => {
  const { from, to } = props;
  const { status, data } = useInvoices(from, to);
  const loadRoute = useLoadRoute();

  return (
    <>
      {status === 'loading' ? (
        <PageStatus />
      ) : status === 'error' ? (
        <PageStatus status="ERROR" />
      ) : (
        <div className="w-full overflow-x-auto rounded-lg shadow-xl">
          <table className="table w-full">
            <thead>
              <tr>
                <th className="bg-slate-200 text-sm">เลข Invoice</th>
                <th className="bg-slate-200 text-sm">วันที่</th>
                <th className="bg-slate-200 text-sm">ชื่อบริษัท</th>
                <th className="bg-slate-200 text-center text-sm">ยอดเงิน (บาท)</th>
                <th className="bg-slate-200 text-center text-sm">ยอดรวม (บาท)</th>
                <th className="bg-slate-200 text-sm"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((invoice) => (
                <tr key={invoice.invoiceNo} className={invoice.checked ? 'text-red-600' : ''}>
                  <th>{invoice.invoiceNo}</th>
                  <th>{invoice.date}</th>
                  <td>{invoice.name || '-'}</td>
                  <td className="text-right">
                    {numberFormatter(2).format(invoice.bankAmount) || '-'}
                  </td>
                  <td className="text-right">{numberFormatter(2).format(invoice.total) || '-'}</td>
                  <td className="text-center">
                    <Link
                      to={`/invoices/${invoice.invoiceNo}`}
                      onMouseEnter={() => loadRoute({ to: invoice.invoiceNo })}
                      className="btn btn-xs gap-2"
                    >
                      ดูข้อมูล
                      <PencilSquareIcon className="hidden h-4 w-4 sm:block" />
                    </Link>
                    {/* <button className="gap-2 btn btn-ghost btn-sm">
                  </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default InvoicesTable;

import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { IInvoiceItem } from '../../../interfaces/invoice';
import ImportQuotationPopup from './ImportQuotationPopup';

interface IImportQuotationToggle {
  invoiceNo: string;
  setItems: (items: IInvoiceItem[]) => void;
  setTotal: (value: number) => void;
  setVat: (value: number) => void;
  setInvoiceAmount: (value: number) => void;
}

const ImportQuotationToggle = (props: IImportQuotationToggle) => {
  const { invoiceNo, setItems, setTotal, setVat, setInvoiceAmount } = props;
  const [isOpen, setIsOpen] = useState(false);
  const popupId = 'new_invoice_popup';
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <label className="btn gap-2" htmlFor={popupId} onClick={togglePopup}>
        Import
        <ClipboardDocumentIcon className="h-5 w-5" />
      </label>
      {isOpen && (
        <>
          <ImportQuotationPopup
            popupId={popupId}
            togglePopup={togglePopup}
            invoiceNo={invoiceNo}
            setItems={setItems}
            setTotal={setTotal}
            setVat={setVat}
            setInvoiceAmount={setInvoiceAmount}
          />
        </>
      )}
    </>
  );
};

export default ImportQuotationToggle;

import { useNavigate } from '@tanstack/react-location';
import { AxiosError } from 'axios';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { pick } from 'underscore';

import {
  calculateWeightedProduct,
  useProductTypeInfo,
  useUpdateWeightedProduct,
} from '../../hooks/product';
import { IWeightedProductConfig } from '../../interfaces/product';
import { IQuotationProductWeighted } from '../../interfaces/quotation';
import { IProductBaseProps } from '../../pages/Product/Product';
import {
  ActionType,
  useProductWeightedFormReducer,
} from '../../reducers/productWeightedFormReducer';
import { getImageUrl } from '../../utils';
import { numberFormatter } from '../../utils/formatter';

interface IProductWeightedFormProps extends IProductBaseProps {
  data: IQuotationProductWeighted;
}

const dimensions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];

const productConfigKeys = [
  'specs',
  'product_type',
  'is_assembling',
  'qty',
  'option_note',
  'weight',
  'weight_total',
  'total',
];

const ProductWeightedForm = (props: IProductWeightedFormProps) => {
  const { data, quotationId, productId } = props;
  const { product } = data;
  const initialConfig = pick(data, productConfigKeys) as IWeightedProductConfig;

  const navigate = useNavigate();

  const [state, dispatch] = useProductWeightedFormReducer(initialConfig);
  const [isDirty, setIsDirty] = useState(true);
  const [showPreview, setShowPreview] = useState(false);

  const typeInfos = useProductTypeInfo(product.id, product.type);
  const mutation = useUpdateWeightedProduct();

  useEffect(() => {
    if (typeInfos.length === product.type && !typeInfos.includes(undefined)) {
      if (state.product_type === '') {
        [...Array(product.type)].map((_, index) => {
          const currentTypeInfos = typeInfos[index];
          // console.log(currentTypeInfos);
          if (currentTypeInfos) {
            dispatch({
              type: ActionType.SET_PRODUCT_TYPE,
              payload: {
                index,
                value: `${currentTypeInfos[0].id}`,
              },
            });
          }
        });
      }
    }
    // console.log(state.product_type);
  }, [typeInfos, product, state.product_type, dispatch]);

  useEffect(() => {
    const note = document.querySelector('[name="option_note"]') as HTMLElement | null;
    const autoCalculate = () => {
      const calcButton = document.getElementById('calculateButton');
      calcButton?.click();
    };

    note?.addEventListener('focus', autoCalculate);

    return () => {
      note?.removeEventListener('focus', autoCalculate);
    };
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    dispatch({
      type: ActionType.INPUT_CHANGE,
      payload: {
        name: event.target.name,
        value: event.target.value,
      },
    });
  };

  const handleProductTypeChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    setIsDirty(true);
    dispatch({
      type: ActionType.SET_PRODUCT_TYPE,
      payload: {
        index,
        value: event.target.value,
      },
    });
  };

  const handleToggleIsAssembling = () => {
    setIsDirty(true);
    dispatch({
      type: ActionType.INPUT_TOGGLE,
    });
  };

  const onCalculate = async () => {
    try {
      const dimensionsObj = dimensions
        .slice(0, product.dimensions)
        .reduce((p, v, i) => ({ ...p, [v]: +state.specs.split('x')[i] }), {});

      const res = await calculateWeightedProduct(
        {
          dimensions: dimensionsObj,
          is_allow_assembling: state.is_assembling,
          product_types: state.product_type,
        },
        product.id
      );
      dispatch({
        type: ActionType.CALCULATE,
        payload: res,
      });
      setIsDirty(false);
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };

  const onSave = async () => {
    try {
      await mutation.mutateAsync({ formData: state, productId });
      navigate({ to: `/quotations/${quotationId}` });
    } catch (err) {
      const error = err as Error | AxiosError;
      toast.error(error.message);
    }
  };
  return (
    <div className="flex flex-col items-center space-y-4">
      <figure className="max-w-xs">
        <img src={getImageUrl(product.preview_image)} alt={product.name} className="rounded-xl" />
        {product.preview_image && (
          <button className="btn btn-block" onClick={() => setShowPreview(true)}>
            Preview
          </button>
        )}
        {showPreview && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
              <div className="relative my-6 mx-auto w-auto max-w-3xl">
                <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                  <div className="flex items-start justify-between rounded-t border-b border-solid border-gray-300 p-5 ">
                    <h3 className="font=semibold text-3xl">Product Preview</h3>
                  </div>
                  <div className="relative flex-auto p-6">
                    <img
                      src={getImageUrl(product.image)}
                      alt={product.name}
                      className="rounded-xl"
                    />
                  </div>
                  <div className="border-blueGray-200 flex items-center justify-end rounded-b border-t border-solid p-6">
                    <button
                      className="background-transparent mr-1 mb-1 px-6 py-2 text-sm font-bold uppercase text-red-500 outline-none focus:outline-none"
                      type="button"
                      onClick={() => setShowPreview(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </figure>
      <h2 className="text-xl font-bold">{product.name}</h2>
      <div className="w-full space-y-2">
        {[...Array(product.type)].map((_, index) => (
          <div key={index} className="form-control-row w-full">
            <label className="label">
              <span className="label-text">ประเภทเหล็ก {index + 1}</span>
            </label>
            {typeInfos && (
              <select
                className="select select-bordered flex-1"
                value={state.product_type.split(',')[index]}
                name="product_type"
                onChange={(e) => {
                  handleProductTypeChange(e, index);
                }}
              >
                {typeInfos[index]?.map((typeInfo) => (
                  <option key={typeInfo.id} value={typeInfo.id}>
                    {typeInfo.product_type.display_name}
                  </option>
                ))}
              </select>
            )}
          </div>
        ))}
        {[...Array(product.dimensions)].map((_, index) => (
          <div key={dimensions[index]} className="form-control-row w-full">
            <label className="label min-w-[3rem]">
              <span className="label-text">ด้าน {dimensions[index]}</span>
            </label>
            <input
              type="number"
              placeholder="ex. 10"
              className="input input-bordered w-full"
              value={state.specs.split('x')[index] ?? ''}
              onChange={(e) => {
                dispatch({
                  type: ActionType.SET_SPEC,
                  payload: {
                    index,
                    value: e.target.value,
                    dimensions: product.dimensions,
                  },
                });
              }}
            />
          </div>
        ))}
        <div className="flex items-center space-x-4">
          <div className="form-control-row w-full">
            <label className="label min-w-[3rem]">
              <span className="label-text">จำนวน</span>
            </label>
            <input
              type="number"
              placeholder="ex. 10"
              className="input input-bordered w-full"
              value={state.qty || ''}
              name="qty"
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
          {product.is_allow_assembling && (
            <div className="form-control">
              <label className="label cursor-pointer space-x-4">
                <span className="label-text">ผูก</span>
                <input
                  type="checkbox"
                  className="toggle"
                  defaultChecked={state.is_assembling}
                  onChange={() => {
                    handleToggleIsAssembling();
                  }}
                />
              </label>
            </div>
          )}
        </div>
        <div className="form-control-row w-full">
          <label className="label min-w-[3rem]">
            <span className="label-text">Note</span>
          </label>
          <input
            type="text"
            placeholder="..."
            className="input input-bordered w-full"
            value={state.option_note}
            name="option_note"
            id="option_note"
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>
      <div className="rounded-box w-full space-y-2 bg-base-300 p-4">
        <div className="grid gap-x-4 sm:grid-cols-2">
          <div className="flex space-x-2">
            <span className="flex-shrink-0 font-bold">ราคาประเมิน</span>
            <span className="w-full text-right">{numberFormatter(2).format(state.total)}</span>
            <span className="text-right">บาท</span>
          </div>
          <div className="flex space-x-2">
            <span className="flex-shrink-0 font-bold">น้ำหนัก</span>
            <span className="w-full text-right">
              {numberFormatter(2).format(state.weight_total)}
            </span>
            <span className="text-right">กก.</span>
          </div>
          <div className="flex space-x-2">
            <span className="flex-shrink-0 font-bold">ราคาประเมิน/ชิ้น</span>
            <span className="w-full text-right">
              {numberFormatter(2).format(state.total / state.qty || 0)}
            </span>
            <span className="text-right">บาท</span>
          </div>
          <div className="flex space-x-2">
            <span className="flex-shrink-0 font-bold">น้ำหนัก/ชิ้น</span>
            <span className="w-full text-right">{numberFormatter(3).format(state.weight)}</span>
            <span className="text-right">กก.</span>
          </div>
        </div>
      </div>
      <div className="flex w-full space-x-2">
        <div className="w-1/2">
          <button className="btn btn-primary btn-block" id="calculateButton" onClick={onCalculate}>
            คำนวณ
          </button>
        </div>
        <div className="w-1/2">
          <button className="btn btn-block" onClick={onSave} disabled={isDirty}>
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductWeightedForm;

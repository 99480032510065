import { useMatch } from '@tanstack/react-location';

import BackButton from '../../components/BackButton';
import PageStatus from '../../components/PageStatus';
import { useInvoiceItem } from '../../hooks/invoice';
import { RouteLocationGenerics } from '../../routes';

const InvoiceItem = () => {
  const {
    params: { invoiceNo, uuid },
  } = useMatch<RouteLocationGenerics>();

  const { status, data } = useInvoiceItem(invoiceNo, uuid);

  return (
    <div className="mx-auto max-w-xl space-y-4">
      <BackButton to={`/invoices/${invoiceNo}`} />
      <div className="rounded-box bg-base-100 px-6 py-4 shadow-lg"></div>
      {status === 'loading' ? (
        <PageStatus />
      ) : status === 'error' ? (
        <PageStatus status="ERROR" />
      ) : data ? (
        <></>
      ) : null}
    </div>
  );
};

export default InvoiceItem;

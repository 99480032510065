export interface IProductsCategoriesResponse {
  id: number;
  name: string;
  order: number;
}

export enum IProductType {
  WEIGHTED = 'weighted',
  NORMAL = 'normal',
  OTHER = 'other',
}

export enum IProductCalculationTypes {
  BAHT = 'baht',
  PERCENT = 'percent',
}

export interface ICategoryProduct {
  type: IProductType;
  normal: INormalProduct | null;
  other: IOtherProduct | null;
  weighted: IWeightedProduct | null;
}

export interface IBaseProduct {
  id: number;
  name: string;
}

export interface IWeightedProduct extends IBaseProduct {
  image: string;
  preview_image: string;
  description: string;
  calculation: string;
  dimensions: number;
  type: number;
  css: string;
  is_allow_assembling: boolean;
  minimum_size: null;
  order: number;
  unit: string;
  price_unit: string;
}

export interface INormalProduct extends IBaseProduct {
  image: string;
  description: string;
  price: string;
  order: number;
  unit: string;
  price_unit: string;
}

export interface IOtherProduct extends IBaseProduct {
  calculation_type: IProductCalculationTypes;
}

export interface IProductTypeInfo {
  url: string;
  id: number;
  product_type: {
    id: number;
    name: string;
    display_name: string;
    weight: number;
    hex_color: string;
  };
  product: {
    id: number;
    name: string;
    image: string;
    description: string;
    calculation: string;
    dimensions: number;
    type: number;
    css: string;
    minimum_size: null;
    order: number;
    unit: string;
    price_unit: string;
  };
}

export interface IWeightedProductConfig {
  specs: string;
  product_type: string;
  is_assembling: boolean;
  qty: number;
  option_note: string;
  weight: number;
  weight_total: number;
  total: number;
}

export type WeightedProductConfigKeys = keyof IWeightedProductConfig;

export interface IWeightedProductDimensionSpecs {
  [key: string]: number;
}

export interface IWeightedProductCalculateBody {
  dimensions: IWeightedProductDimensionSpecs;
  is_allow_assembling: boolean;
  product_types: string;
}

export interface IWeightedProductCalculateResponse {
  weight: number;
  price: number;
  product_price: number;
}

export interface INormalProductConfig {
  qty: number;
  option_note: string;
  total: number;
}

export type NormalProductConfigKeys = keyof INormalProductConfig;

export interface IOtherProductConfig {
  qty: number;
  option_note: string;
  option_value: number;
}

export type OtherProductConfigKeys = keyof IOtherProductConfig;

import { useState } from 'react';
import { useCookies } from 'react-cookie';

import CustomerForm from '../../components/Customer/CustomerForm';
import SearchCustomer from '../../components/Customer/SearchCustomer';

type ActiveTab = 'EXISTING_CUSTOMER' | 'NEW_CUSTOMER';

const Customers = () => {
  const [cookies, setCookie] = useCookies(['customer-active-tab']);
  const [activeTab, setActiveTab] = useState<ActiveTab>(cookies['customer-active-tab']);
  console.log(cookies);

  const onSwitchTab = (tab: ActiveTab) => {
    setActiveTab(tab);
    setCookie('customer-active-tab', tab, {
      path: '/',
    });
  };
  return (
    <div className="rounded-box bg-base-100 px-4 py-4 shadow-lg md:px-8">
      <div className="flex items-center justify-center">
        <div className="tabs tabs-boxed">
          <button
            className={`tab tab-lg text-base md:text-lg ${
              activeTab === 'NEW_CUSTOMER' ? '' : 'tab-active'
            }`}
            onClick={() => onSwitchTab('EXISTING_CUSTOMER')}
          >
            สร้างจากลูกค้าเดิม
          </button>
          <button
            className={`tab tab-lg text-base md:text-lg ${
              activeTab === 'NEW_CUSTOMER' ? 'tab-active' : ''
            }`}
            onClick={() => onSwitchTab('NEW_CUSTOMER')}
          >
            สร้างลูกค้าใหม่
          </button>
        </div>
      </div>
      <div>
        {activeTab === 'NEW_CUSTOMER' ? <CustomerForm isNewCustomer={true} /> : <SearchCustomer />}
      </div>
    </div>
  );
};

export default Customers;

import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import CreateQuotationPopup from './CreateQuotationPopup';

interface ICreateQuotationToggleProps {
  label: string;
  onSubmitClick: (staffId: string) => Promise<void>;
  onMouseEnter?: React.MouseEventHandler<HTMLLabelElement>;
}

const CreateQuotationToggle = (props: ICreateQuotationToggleProps) => {
  const { label, onSubmitClick, onMouseEnter } = props;
  const [isOpen, setIsOpen] = useState(false);
  const popupId = 'select_staff_popup';
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <label
        className="btn btn-block gap-2"
        htmlFor={popupId}
        onClick={togglePopup}
        onMouseEnter={onMouseEnter}
      >
        {label}
        <DocumentPlusIcon className="hidden h-4 w-4 sm:block" />
      </label>
      {isOpen && (
        <>
          <CreateQuotationPopup
            popupId={popupId}
            togglePopup={togglePopup}
            onSubmitClick={onSubmitClick}
          />
        </>
      )}
    </>
  );
};

export default CreateQuotationToggle;

import { MapIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { useMatch } from '@tanstack/react-location';
import { format } from 'date-fns';
import { toJpeg } from 'html-to-image';
import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import CreateMFOToggle from '../../components/Popup/CreateMFO/CreateMFOToggle';
import QuotationProductNormalRow from '../../components/Quotation/Print/QuotationProductNormalRow';
import QuotationProductOtherRow from '../../components/Quotation/Print/QuotationProductOtherRow';
import QuotationProductWeightedRow from '../../components/Quotation/Print/QuotationProductWeightedRow';
import { useCreateTrello, useMFO, useMFOPrint, useUpdateMFO } from '../../hooks/quotation';
import {
  IQuotationMFOPrintWeightedItem,
  IQuotationPrintNormalItem,
  IQuotationPrintOtherItem,
} from '../../interfaces/quotation';
import { RouteLocationGenerics } from '../../routes';
import { numberFormatter } from '../../utils/formatter';

const QuotationMFOPrint = () => {
  const {
    params: { mfoId },
  } = useMatch<RouteLocationGenerics>();

  const { data: mfo } = useMFO(mfoId);
  const { data: mfoPrint } = useMFOPrint(mfoId);
  const { mutateAsync: updateMFO } = useUpdateMFO();
  const { mutateAsync: createTrello } = useCreateTrello();
  const [isCreatingTrello, setIsCreatingTrello] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const reactToPrintContent = useCallback(() => {
    return ref.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: mfoId,
    removeAfterPrint: true,
  });

  const customerPrefix = (prefix: 'mr' | 'engineer' | 'org' | 'other', prefixOther?: string) => {
    const mapping = {
      mr: 'คุณ',
      engineer: 'ช่าง',
      org: 'บจก.',
    };
    if (prefix === 'other') {
      return prefixOther;
    } else {
      return mapping[prefix];
    }
  };

  const onCreateTrello = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    setIsCreatingTrello(true);

    toJpeg(ref.current, {
      cacheBust: false,
    })
      .then(async (dataUrl) => {
        await createTrello({
          mfoId,
          formData: {
            image: dataUrl,
          },
        });
        toast.success('สร้าง Trello Card สำเร็จ', { autoClose: false });
        setIsCreatingTrello(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref, createTrello, mfoId]);
  return (
    <div className="mx-auto max-w-[980px] space-y-4 px-4">
      <div className="navbar rounded-box bg-base-100 shadow-xl">
        <div className="navbar-start"></div>
        <div className="navbar-end space-x-2">
          <div>
            <CreateMFOToggle
              isEdit={true}
              mfo={mfo}
              onSubmitClick={async (formData) => {
                await updateMFO({
                  formData,
                  mfoId,
                });
              }}
            />
          </div>
          <button className="btn gap-2" onClick={handlePrint}>
            พิมพ์
            <PrinterIcon className="h-4 w-4" />
          </button>
          <button className="btn gap-2" onClick={onCreateTrello} disabled={isCreatingTrello}>
            <span>Create Trello</span>
            <svg
              className={`h-5 w-5 animate-spin text-white ${isCreatingTrello ? '' : 'hidden'}`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {/* <div className="rounded-box space-y-8 bg-base-100 px-4 py-4 shadow-lg md:px-8"> */}
      <div className="space-y-8 p-4">
        {mfo && mfoPrint && (
          <div ref={ref}>
            <style>
              {`
              @media print {
                html {
                  font-size: 12px;
                  padding: 3rem;
                }
              }
              `}
            </style>
            <div className="space-y-4 bg-white font-sarabun">
              <div className="flex space-x-6">
                <div className="flex-1 flex-shrink-0">
                  <ul className="flex flex-wrap gap-1">
                    {Object.entries(mfoPrint.used_product_type).map(([key, value]) => (
                      <li
                        key={key}
                        style={{
                          backgroundColor: `#${value}`,
                        }}
                        className="px-3 py-2"
                      >
                        {key}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-shrink-0">
                  <div className="flex items-center space-x-2 text-right">
                    <div className="flex-1">
                      <span>ตามใบเสนอราคา</span>
                    </div>
                    <div className="w-[6.25rem]">
                      <span>{mfo.quotation.running_number}</span>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 text-right">
                    <div className="flex-1">
                      <span>วันที่</span>
                    </div>
                    <div className="w-[6.25rem]">
                      <span>{format(new Date(mfo.create_date), 'dd/MM/yyyy')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider" />
              <h2 className="text-center text-xl font-bold">
                ใบสั่งงานเลขที่ {mfo.running_number}
              </h2>
              <div className="flex border border-black p-4">
                <div className="grid w-full grid-cols-2">
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-col">
                      <span>ชื่อลูกค้า</span>
                      <span className="text-2xl font-bold">
                        {customerPrefix(mfo.quotation.prefix, mfo.quotation.prefix_other)}
                        {mfo.name}
                      </span>
                    </div>
                    <div className="flex space-x-2">
                      <span>โทร</span>
                      <span className="flex-1">{mfo.phone}</span>
                    </div>
                    <div className="flex space-x-2">
                      <span>ผู้สั่งงาน</span>
                      <span className="flex-1">คุณ{mfo.quotation.staff.name}</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex space-x-2">
                      <span className="">ยอดคงเหลือ</span>
                      <span className="flex-1">
                        {numberFormatter(2).format(
                          mfo.is_earnest ? mfo.quotation_total - mfo.earnest : 0
                        )}
                      </span>
                    </div>
                    {mfo.is_delivery ? (
                      <>
                        <div className="flex space-x-2">
                          <span className="">Area</span>
                          <span className="flex-1">{mfo.area || '-'}</span>
                        </div>
                        <div className="flex space-x-2">
                          <span className="">จัดส่งที่</span>
                          <span className="flex flex-1 gap-2">
                            {mfo.map_link && (
                              <a
                                href={mfo.map_link}
                                target="_blank"
                                className="btn btn-square btn-xs"
                                rel="noreferrer"
                              >
                                <MapIcon className="h-3 w-3" />
                              </a>
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="flex space-x-2">
                        <span className="">รับเอง</span>
                      </div>
                    )}
                    <div className="flex space-x-2">
                      <span className="">วันที่จัดส่ง/รับ</span>
                      <span className="flex-1">
                        {format(new Date(mfo.deliver_date), 'MMMM d, yyyy') || '-'}{' '}
                        {mfo.deliver_time && mfo.deliver_time !== 'none'
                          ? ` (${mfo.deliver_time === 'morning' ? 'เช้า' : 'บ่าย'})`
                          : ''}
                      </span>
                    </div>
                    {mfo.is_delivery ? (
                      <>
                        <div className="flex space-x-2 text-2xl">
                          <span className="">จัดส่ง</span>
                          <span className="flex-1 text-red-600">
                            {`${mfo.delivery_name}(${mfo.delivery_phone})`}
                          </span>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="flex space-x-2 text-2xl">
                      <span className="">Comment</span>
                      <span className="flex-1 text-red-600">{mfo.comment || '-'}</span>
                    </div>
                  </div>
                </div>
                <QRCodeSVG value={window.location.href} />
              </div>
              <div className="quotation-table">
                <table className="w-full">
                  <thead className="text-center text-[0.85em]">
                    <tr>
                      <th className="w-[2.25rem]">
                        <span className="block">
                          ลำดับ <br />
                          ITEM
                        </span>
                      </th>
                      <th className="">
                        <span className="block">
                          รายการ <br />
                          DESCRIPTION
                        </span>
                      </th>
                      <th className="w-[7.5%]">
                        <span className="block">
                          จำนวน <br />
                          QUANTITY
                        </span>
                      </th>
                      <th className="w-[10%]">
                        <span className="block">นน. ต่อชิ้น</span>
                      </th>
                      <th className="w-[10%]">
                        <span className="block">
                          น้ำหนักรวม <br />
                          WEIGHT
                        </span>
                      </th>
                      <th className="w-[2.5%]">
                        <span className="block">เหล็ก</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mfoPrint.items.map((item, index) => {
                      if (item.type === 'weighted_product') {
                        return (
                          <QuotationProductWeightedRow
                            priceShow={true}
                            key={`${item.type}_${index}`}
                            item={item as IQuotationMFOPrintWeightedItem}
                            index={index}
                            isMFO={true}
                          />
                        );
                      }
                      if (item.type === 'normal_product') {
                        return (
                          <QuotationProductNormalRow
                            priceShow={true}
                            key={`${item.type}_${index}`}
                            item={item as unknown as IQuotationPrintNormalItem}
                            index={index}
                            isMFO={true}
                          />
                        );
                      }
                      return (
                        <QuotationProductOtherRow
                          priceShow={true}
                          key={`${item.type}_${index}`}
                          item={item as unknown as IQuotationPrintOtherItem}
                          index={index}
                          isMFO={true}
                        />
                      );
                    })}
                    <tr className="quotation-table-footer">
                      <td colSpan={2}></td>
                      <td colSpan={2} className="!border-gray-300">
                        น้ำหนักรวม
                      </td>
                      <td className="!border-gray-300 font-bold">
                        {numberFormatter(2).format(mfoPrint.net_weight)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuotationMFOPrint;
